import React from "react";
import styles from "./facilities.module.css";

export const Facility = ({ icon, label }) => {
  return (
    <div className={styles.iconTextBox}>
      {icon}
      <p className={styles.iconsText}>{label}</p>
    </div>
  );
};
