import React from "react";
import CityData from "../../staticData/cityData";
import styles from "./searchBar.module.css";
import { SearchIcon } from "../svgs/rentpal";

const SearchBar = () => {
  return (
    <div className={styles.searchBarMainContainer}>
      <form action="/search" method="GET" className={styles.formContainer}>
        <select className={styles.selectCityField}>
          {CityData.map((city, index) => (
            <option
              className={styles.citiesNames}
              key={index}
              value={city.name}
            >
              {city.name}
            </option>
          ))}
        </select>
        <div className={styles.breakline}></div>
        <div className={styles.fieldIconContainer}>
          <input
            type="text"
            id="searchQuery"
            name="q"
            placeholder="Location"
            className={styles.locationField}
          />
          <SearchIcon className={styles.searchIcon} />
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
