import React from "react";
import styles from "./hostLanguage.module.css";

const HostLanguage = () => {
  return (
    <div className={styles.hostLanguageContainer}>
      <h1>Host Language</h1>
      <div className={styles.hostLanguageCheckboxContainer}>
        <div className={styles.checkboxColumn}>
          <label>
            <input type="checkbox" /> Wifi
          </label>
          <label>
            <input type="checkbox" /> Washer
          </label>
          <label>
            <input type="checkbox" /> Air conditioning
          </label>
        </div>
        <div className={styles.checkboxColumn}>
          <label>
            <input type="checkbox" /> Kitchen
          </label>
          <label>
            <input type="checkbox" /> Dryer
          </label>
          <label>
            <input type="checkbox" /> Heating
          </label>
        </div>
      </div>
      <a className={styles.optionLink}>Show more</a>
    </div>
  );
};

export default HostLanguage;
