import React from "react";
import styles from "./topTier.module.css";
import { TopTierIcon } from "../svgs/rentpal";

const TopTier = () => {
  return (
    <div className={styles.topTierContainer}>
      <h1 className={styles.topTierContainerH1}>Top-tier stays</h1>
      <div className={styles.topTierContentContainer}>
        <div className={styles.topTierIconContentContainer}>
          <TopTierIcon />
          <h2 className={styles.topTierIconContentContainerH2}>
            Guest Favourites
          </h2>
          <p className={styles.topTierIconContentContainerP}>
            The most loved homes on Airbnb,
            <br />
            according to guests
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopTier;
