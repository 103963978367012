import React, { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminPanel from "./AdminPanel";
import UserPanel from "./UserPanel";
import styles from "./meterReadings.module.css";

const ESP32WebSocket = () => {
  const serverURL = "wss://server.pal.rent/api/v1/websocket/";
  // const serverURL = "ws://localhost:3000/api/v1/websocket/";
  const [open, setOpen] = useState(true);
  const [meterNumber, setMeterNumber] = useState("");
  const [webSocketUrl, setWebSocketUrl] = useState(null);
  const [adminView, setAdminView] = useState(false);
  const [meters, setMeters] = useState([]);
  const [userData, setUserData] = useState(null);

  const { lastMessage, sendMessage, readyState } = useWebSocket(webSocketUrl, {
    onOpen: () => console.log("WebSocket connection opened"),
    onClose: () => console.log("WebSocket connection closed"),
    onError: (event) => console.error("WebSocket error: ", event),
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    if (lastMessage !== null) {
      if (typeof lastMessage.data === "string") {
        handleData(lastMessage.data);
      } else if (lastMessage.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            const text = reader.result;
            handleData(text);
          } catch (error) {
            console.error("Failed to parse JSON from Blob:", error);
          }
        };
        reader.readAsText(lastMessage.data);
      } else if (lastMessage.data instanceof ArrayBuffer) {
        const textData = new TextDecoder().decode(lastMessage.data);
        handleData(textData);
      } else if (lastMessage.data instanceof Buffer) {
        // Convert Buffer to text
        const textData = Buffer.from(lastMessage.data).toString();
        handleData(textData);
      }
    }
  }, [lastMessage]);

  const handleData = (data) => {
    try {
      const parsedData = JSON.parse(data);
      // console.log(
      //   adminView ? "Parsed Admin Data:" : "Parsed User Data:",
      //   parsedData
      // );

      if (adminView) {
        if (parsedData.type === "meterUpdate") {
          updateMetersData(parsedData.meterNumber, parsedData.message);
        }
      } else {
        setUserData(parsedData);
      }
    } catch (error) {
      console.error("Failed to parse JSON:", error);
    }
  };

  const updateMetersData = (meterNumber, bufferData) => {
    // console.log("bufferDATA=====", bufferData);
    try {
      // Check if bufferData is the expected buffer format
      if (
        bufferData &&
        bufferData.type === "Buffer" &&
        Array.isArray(bufferData.data)
      ) {
        // Convert the buffer data to a string
        const jsonString = new TextDecoder().decode(
          Uint8Array.from(bufferData.data)
        );

        // Parse the JSON string into an object
        const data = JSON.parse(jsonString);
        // console.log(data);

        setMeters((prevMeters) => {
          const existingMeterIndex = prevMeters.findIndex(
            (meter) => meter.number === meterNumber
          );

          const updatedMeter = {
            number: meterNumber,
            currentAmps: data.currentAmps || "-",
            allocatedUnits: data.allocatedUnits || "-",
            purchasedUnits: data.purchasedUnits || "-",
            totalConsumedEnergy: data.totalConsumedEnergy || "-",
            currentPowerKilowatts: data.currentPowerKilowatts || "-",
            relayState: data.relayState !== undefined ? data.relayState : "-",
          };

          let updatedMeters;
          if (existingMeterIndex !== -1) {
            updatedMeters = [...prevMeters];
            updatedMeters[existingMeterIndex] = updatedMeter;
          } else {
            updatedMeters = [...prevMeters, updatedMeter];
          }

          return updatedMeters;
        });
      } else {
        console.log("Received non-buffer data, ignoring:", bufferData);
      }
    } catch (error) {
      console.error("Failed to parse Buffer data:", error);
    }
  };

  const handleDialogClose = () => {
    if (meterNumber.trim() === "") {
      console.error("Meter number cannot be empty");
      return;
    }
    const url = `${serverURL}${meterNumber}`;
    console.log("Constructed WebSocket URL:", url);
    setWebSocketUrl(url);
    setAdminView(meterNumber.toLowerCase() === "admin");
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleDialogClose();
    }
  };

  const handleButtonClick = (command, meterNumber, data = {}) => {
    if (readyState === WebSocket.OPEN) {
      let message = {
        command:
          command.toLowerCase() === "updateunits" ? "updateUnits" : "relay", // Always use 'relay' for relay commands
        targetMeterNumber: meterNumber,
      };

      if (command === "updateUnits" && adminView) {
        message = {
          ...message,
          allocatedUnits: data.allocatedUnits || null,
          purchasedUnits: data.purchasedUnits || null,
        };
      } else if (command === "ON" || command === "OFF") {
        if (adminView) {
          message = {
            ...message,
            command: "relay",
            relayCommand: command,
          };
        } else {
          toast.error("You do not have permission to control the relay");
          return;
        }
      }

      console.log("Sending message:", JSON.stringify(message)); // Add this line for debugging
      sendMessage(JSON.stringify(message));
      toast.success(`Command "${command}" sent successfully!`);
    } else {
      toast.error("WebSocket is not open");
    }
  };

  if (webSocketUrl === null) {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Enter Meter Number</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Meter Number"
            type="text"
            fullWidth
            variant="standard"
            value={meterNumber}
            onChange={(e) => setMeterNumber(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Connect
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className={styles.esp32WebSocket}>
      <h1 className={styles.header}>Meter Number: {meterNumber}</h1>
      <div className={styles.dataContainer}>
        {adminView ? (
          <AdminPanel meters={meters} handleButtonClick={handleButtonClick} />
        ) : (
          <UserPanel
            userData={userData}
            handleButtonClick={handleButtonClick}
          />
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ESP32WebSocket;
//
