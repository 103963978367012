import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
// import Layout from "../../components/layout/layout";
// import ViewAllProperties from "../managers/viewAllProperties/viewAllProperties";
import ViewAllProperties from "../viewAllProperties/viewAllProperties";
import SearchBar from "../../components/searchBar/searchBar";
import { Facilities } from "../../components/facilities/facilities";

function Home() {
  let navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const { authData } = useAuth();

  const [showLogin, setShowLogin] = useState(true);

  const handleLoginClick = (prev) => {
    setShowLogin((prev) => !prev);
  };

  useEffect(() => {
    if (authData) {
      setUserData(authData);
      // console.log(userData);
    } else {
      const storedUserData = localStorage.getItem("userData");

      if (storedUserData) {
        setUserData(JSON.parse(storedUserData));
      }
    }
  }, [authData]);

  return (
    <>
      <SearchBar />
      <Facilities />
      <div>
        <h1>
          {/* {JSON.stringify(authData)} */}
          Hi{" "}
          {userData && userData.data && userData.data.name
            ? userData.data.name
            : "User"}
        </h1>
        <ViewAllProperties />
      </div>
    </>
  );
}

export default Home;
