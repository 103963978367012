import React, { useState, useEffect } from "react";
import styles from "./superAdminDash.module.css";
import { useNavigate } from "react-router-dom";

const sampleAdmins = [
  {
    name: "Hostel 1",
    students: 100,
    availableSeats: 20,
    costPerSeat: 500,
    status: "Running",
    doorUnlocks: 50,
    electricityConsumed: 200,
  },
  {
    name: "Hostel 2",
    students: 120,
    availableSeats: 15,
    costPerSeat: 450,
    status: "Suspended",
    doorUnlocks: 80,
    electricityConsumed: 250,
  },
];

function SuperAdminDashboard() {
  const [admins, setAdmins] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setAdmins(sampleAdmins);
  }, []);

  const handleChangeStatus = (adminId, status) => {
    const updatedAdmins = admins.map((admin) =>
      admin.name === adminId ? { ...admin, status } : admin
    );
    setAdmins(updatedAdmins);
  };

  const data = [
    { label: "Total Admins", amount: 500 },
    { label: "Total Subscribed", amount: 5 },
    { label: "Total Unsubscribed", amount: 50 },
    { label: "Total Listed Properties", amount: 60 },
    { label: "Properties Managed by RentPal", amount: 15 },
    { label: "Total Generated Revenue", amount: 850000 },
    { label: "Revenue from Managed Properties", amount: 450000 },
    { label: "Net Profit of Managed Properties", amount: 47000 },
  ];

  const totalAmount = data.reduce((sum, item) => sum + item.amount, 0);

  return (
    <div className={styles.dashboardWrapper}>
      {/* Sidebar */}
      <div className={styles.sidebar}>
        <button
          className={styles.sidebarButton}
          onClick={() => navigate("/manageAdmins")}
        >
          Manage Admins
        </button>
        <button
          className={styles.sidebarButton}
          onClick={() => navigate("/smartDevices")}
        >
          Manage Smart Devices
        </button>
        <button
          className={styles.sidebarButton}
          onClick={() => navigate("/financials")}
        >
          Financials
        </button>
        <button
          className={styles.sidebarButton}
          onClick={() => navigate("/automatedAdverts")}
        >
          Automated Adverts
        </button>
        <button
          className={styles.sidebarButton}
          onClick={() => navigate("/announcements")}
        >
          Announcements
        </button>

        <button
          className={styles.sidebarButton}
          onClick={() => navigate("/stats")}
        >
          Statistics
        </button>
        <button
          className={styles.sidebarButton}
          onClick={() => navigate("/crashReports")}
        >
          Crash reports
        </button>
      </div>

      {/* Main Content */}
      <div className={styles.adminDashboardContent}>
        <h1 className={styles.textPrimary}>Welcome, Muhammad Talha</h1>
        <p className={styles.textSecondary}>
          Guests can reserve your place 24 hours after you publish—here’s how to
          prepare.
        </p>

        {/* Summary Table */}
        <div className={styles.summaryTableWrapper}>
          <table className={styles.summaryTable}>
            <thead>
              <tr>
                <th>Entry</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item.label}</td>
                  <td>{item.amount}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td>Sum</td>
                <td>${totalAmount.toLocaleString()}/=</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SuperAdminDashboard;

// Super Admin:
// 1. CRUD operation for admins
// 2. List of admins (hostels)with the total number of students they have, no. Of available seats in hostels
// 3. Total revenue from each admin i.e. cost per seat x total seats in hostel etc, total revenue of each admin
// 4. Status of admin i.e. running, pending, suspended, total door unlocks, total electricity units consumed etc
// 5. Options to send message to admins i.e. bill payment and alter their status accordingly.
// 6. Give news/announcements to admins
