import React from "react";
import styles from "./serviceAnimal.module.css";
import serviceAnimalImage from "../../assets/images/allow-pets.jpg";
import { CloseIcon } from "../svgs/rentpal";

const ServiceAnimal = ({ onClose }) => {
  return (
    <div className={styles.serviceAnimalsMainContainer}>
      <div className={styles.serviceAnimalsContainer}>
        <div className={styles.closeBtnContainer}>
          <CloseIcon onClick={onClose} className={styles.closeButton} />
        </div>
        <img
          src={serviceAnimalImage}
          alt="Service animal with handler"
          className={styles.serviceAnimalsImage}
        />
        <div className={styles.serviceAnimalsContent}>
          <h2>Service animals</h2>
          <p>
            Service animals aren't pets, so there's no need to add them here.
          </p>
          <p>
            Traveling with an emotional support animal? Check out our
            <br />
            <a href="/accessibility-policy">accessibility policy</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceAnimal;
