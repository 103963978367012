import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Create a context
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState("");
  const navigate = useNavigate();

  // Load authData from localStorage on initial render
  useEffect(() => {
    const storedAuthData = localStorage.getItem("authData");
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      setAuthData(parsedAuthData);
      setToken(parsedAuthData?.token || ""); // Safely access token
    }
    setLoading(false);
  }, []);

  // Function to handle login
  const login = (userData) => {
    try {
      setAuthData(userData);
      localStorage.setItem("authData", JSON.stringify(userData)); // Persist data
      toast.success("Logged in successfully!");
      navigate("/"); // Navigate to the homepage or dashboard
    } catch (error) {
      console.error("Login error:", error);
      toast.error("Login failed! Please try again.");
    }
  };

  // Function to handle logout
  const logout = () => {
    try {
      setAuthData(null);
      localStorage.removeItem("authData");
      toast.success("Logged out successfully!");
      navigate("/login"); // Navigate to login page
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("Logout failed! Please try again.");
    }
  };

  // Optional function to store social data
  const updateSocialData = (socialData) => {
    try {
      localStorage.setItem("socialData", JSON.stringify(socialData)); // Persist social data
    } catch (error) {
      console.error("Error updating social data:", error);
    }
  };

  // Role-based authentication checks
  const authenticatedSuperAdmin = useMemo(
    () => authData?.data?.role === 0,
    [authData]
  );

  const authenticatedAdmin = useMemo(
    () => authData?.data?.role === 1,
    [authData]
  );

  const authenticatedUser = useMemo(
    () => authData?.data?.role === 2,
    [authData]
  );

  return (
    <AuthContext.Provider
      value={{
        authData,
        login,
        logout,
        updateSocialData,
        token,
        authenticatedSuperAdmin,
        authenticatedAdmin,
        authenticatedUser,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to consume AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
