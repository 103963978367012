import { React } from "react";
import styles from "./facilitiesDialog.module.css";
import Amenities from "../amenities/amenities";
import PriceSlider from "../priceSlider/priceSlider";
import BedsBathroom from "../bedsBathroom/bedsBathroom";
import BookingOptions from "../bookingOptions/bookingOptions";
import TopTier from "../topTier/topTier";
import PropertyType from "../propertyType/propertyType";
import AccessibilityFeatures from "../accessibilityFeatures/accessibilityFeatures";
import HostLanguage from "../hostLanguage/hostLanguage";
import PlaceType from "../placeType/placeType";

const FacilitiesDialog = ({ onClose }) => {
  return (
    <div className={styles.facilitiesDialog}>
      <div className={styles.facilitiesDialogContainer}>
        <div className={styles.facilitiesDialogHeaderContainer}>
          <div className={styles.dialogHeaderContent}>
            <div className={styles.facilitiesDialogCloseIcon} onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.9447 1.35149C21.2539 1.04232 21.2539 0.541052 20.9447 0.23188C20.6356 -0.0772932 20.1343 -0.0772932 19.8251 0.23188L10.6048 9.45222L1.38449 0.231946C1.07532 -0.0772264 0.574049 -0.0772266 0.264877 0.231946C-0.0442962 0.541119 -0.0442959 1.04239 0.264877 1.35156L9.48516 10.5718L0.231879 19.8251C-0.077293 20.1343 -0.0772933 20.6356 0.231879 20.9447C0.541052 21.2539 1.04232 21.2539 1.35149 20.9447L10.6048 11.6915L19.8581 20.9448C20.1673 21.254 20.6686 21.254 20.9777 20.9448C21.2869 20.6356 21.2869 20.1344 20.9777 19.8252L11.7244 10.5718L20.9447 1.35149Z"
                  fill="black"
                />
              </svg>
            </div>
            <div>
              <h1 className={styles.dialogHeaderTitle}>Filters</h1>
            </div>
          </div>
        </div>
        <div className={styles.facilitiesDialogContent}>
          <PlaceType />
          <div className="break-line"></div>
          <h1>Price range</h1>
          <p>Nightly prices before fees and taxes</p>
          <PriceSlider />
          <div className="break-line"></div>
          <BedsBathroom />
          <div className="break-line"></div>
          <PropertyType />
          <div className="break-line"></div>
          <Amenities />
          <div className="break-line"></div>
          <BookingOptions />
          <div className="break-line"></div>
          <TopTier />
          <div className="break-line"></div>
          <AccessibilityFeatures />
          <br></br>
          <div className="break-line"></div>
          <HostLanguage />
        </div>
        <div className={styles.facilitiesDialogFooterContainer}>
          <div className={styles.facilitiesDialogFooterContent}>
            <button>Clear all</button>
            <div className={styles.facilitiesDialogFooterLinkBtn}>
              <a>Show 1,000+ places</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitiesDialog;
