import React, { useState } from "react";
import styles from "./Feedback.module.css";
import ConfirmationModal from "../../components/confirmationModal/confirmationModal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the toastify CSS

function Feedback() {
  const [feedback, setFeedback] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModalOpen(true); // Open the confirmation modal
  };

  const handleConfirm = () => {
    // Show success toast notification
    toast.success("Feedback submitted successfully!");
    setFeedback(""); // Clear the feedback after submission
    setIsModalOpen(false); // Close the modal
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close the modal without submitting
  };

  return (
    <div className={styles.pageContainer}>
      <section className={styles.feedbackCard}>
        <h2>Feedback</h2>
        <p>We value your feedback! Please share your thoughts below.</p>
        <form onSubmit={handleSubmit}>
          <textarea
            className={styles.textArea}
            value={feedback}
            onChange={handleFeedbackChange}
            placeholder="Type your feedback here..."
            rows="6"
          />
          <button type="submit" className={styles.submitButton}>
            Submit Feedback
          </button>
        </form>
      </section>

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        message="Are you sure you want to submit your feedback?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      {/* Toast container to display the toast notifications */}
      <ToastContainer
        position="top-right"
        autoClose={5000} // Toast disappears after 5 seconds
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default Feedback;
