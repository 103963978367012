import React from "react";
import styles from "./socialSignIn.module.css";

const SocialSignIn = (props) => {
  return (
    <>
      <div className={styles.socialSigninMain} onClick={props.onClick}>
        <img
          src={props.icon}
          className={styles.socialSigninMainImgIcon}
          alt={`${props.socialPlatform} icon`}
        />
        <h1 className={styles.socialSigninMainH1}>
          Continue with {props.socialPlatform}
        </h1>
        {props.children}
      </div>
    </>
  );
};

export default SocialSignIn;
