import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD9xon2ldfUHpFeKgxw5RVPdmQovAmrQ7U",
  authDomain: "property-ms-a6b1a.firebaseapp.com",
  projectId: "property-ms-a6b1a",
  storageBucket: "property-ms-a6b1a.appspot.com",
  messagingSenderId: "348216175059",
  appId: "1:348216175059:web:ca882b253cf169ace36726",
  measurementId: "G-GWJJTNPH0Y",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };

// below this line are the credentials of someone else
// import { initializeApp } from "firebase/app";
// import { getAuth, GoogleAuthProvider } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyCwL4mYYGeuqjkYgktTr3fEp4MlUc7GTm4",
//   authDomain: "hamza-portfolio-f30ba.firebaseapp.com",
//   projectId: "hamza-portfolio-f30ba",
//   storageBucket: "hamza-portfolio-f30ba.appspot.com",
//   messagingSenderId: "526692752996",
//   appId: "1:526692752996:web:f17741db0f840ad5ee4971",
//   measurementId: "G-TWBJEEF714",
// };

// const app = initializeApp(firebaseConfig);

// const auth = getAuth(app);
// const provider = new GoogleAuthProvider();
// export { auth, provider };
