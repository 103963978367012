import React, { useState } from "react";
import "./accessibilityFeatures.css";
import SelectionsPanel from "../selectionPanel/selectionPanel";

const AccessibilityFeatures = () => {
  return (
    <div className="accessibility-features-container">
      <SelectionsPanel title={"Accessibility features"} />
    </div>
  );
};

export default AccessibilityFeatures;
