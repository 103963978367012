import React, { useState, useCallback } from "react";
import styles from "./Dashboard.module.css";
import Dropdown from "../../../assets/svgs/dropdown.svg";
import { useNavigate } from "react-router-dom";

const AdminDash = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className={styles.adminDashWrapper}>
      <div className={styles.adminDashContainer}>
        <button
          className={styles.dashButton}
          onClick={() => navigate("/dashboard/listings")}
        >
          Listings
        </button>
        <button
          className={styles.dashButton}
          onClick={() => navigate("/dashboard/residentRequests")}
        >
          Resident Requests
        </button>
        <button
          className={styles.dashButton}
          onClick={() => navigate("/dashboard/inbox")}
        >
          Inbox
        </button>
        <button
          className={styles.dashButton}
          onClick={() => navigate("/dashboard/financials")}
        >
          Financials
        </button>
        <div className={styles.menuContainer}>
          <button className={styles.dashButton} onClick={toggleMenu}>
            Menu <img src={Dropdown} alt="Dropdown" />
          </button>
          {menuOpen && (
            <div className={styles.dropdownMenu}>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/dashboard/announcements")}
              >
                Announcements
              </button>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/dashboard/automatedAdverts")}
              >
                Automated Adverts
              </button>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/dashboard/smartDevices")}
              >
                Smart Devices
              </button>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/feedback")}
              >
                Feedback
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SuperAdminDash = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.adminDashWrapper}>
      <div className={styles.adminDashContainer}>
        <div className={styles.menuContainer}>
          {/* Add Super Admin-specific menu or functionality */}
        </div>
      </div>
    </div>
  );
};

const ResidentDash = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  // useCallback to memoize the function and avoid unnecessary re-renders
  const toggleMenu = useCallback(() => {
    setMenuOpen((prevState) => !prevState);
  }, []);

  return (
    <div className={styles.adminDashWrapper}>
      <div className={styles.adminDashContainer}>
        <button className={styles.dashButton} onClick={() => navigate("/")}>
          Properties
        </button>
        <button
          className={styles.dashButton}
          onClick={() => navigate("/dashboard/guests")}
        >
          Add Guests
        </button>
        <button
          className={styles.dashButton}
          onClick={() => navigate("/dashboard/my-payments")}
        >
          Payments
        </button>

        <div className={styles.menuContainer}>
          <button className={styles.dashButton} onClick={toggleMenu}>
            Menu <img src={Dropdown} alt="Dropdown" />
          </button>

          {menuOpen && (
            <div className={styles.dropdownMenu}>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/dashboard/become-admin")}
              >
                List your property
              </button>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/dashboard/smartDevices")}
              >
                Smart Devices
              </button>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/dashboard/inbox")}
              >
                Inbox
              </button>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/dashboard/support")}
              >
                Need Help?
              </button>
              <button
                className={styles.dashButton}
                onClick={() => navigate("/feedback")}
              >
                Feedback
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDash;
export { SuperAdminDash, ResidentDash };
