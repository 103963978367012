import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./manageAdmins.module.css";
import { Checkbox } from "@mui/material";
import axios from "axios";
import { URL } from "../../../../route";
import { toast } from "react-toastify";
import { useAuth } from "../../../../context/AuthContext";

const listingsApprovalRequests = [
  { id: 1, listing: "Apartment 101", owner: "John Doe" },
  { id: 2, listing: "Villa 202", owner: "Jane Smith" },
  { id: 3, listing: "Office Suite 303", owner: "Mike Johnson" },
];

function ManageAdmins() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const [selectedListings, setSelectedListings] = useState([]);
  const [pendingManagers, setPendingManagers] = useState([]);
  const [error, setError] = useState(null);
  const [adminsData, setAdminsData] = useState([]);

  // Fetch Pending Managers and Active Admins on Component Mount
  useEffect(() => {
    const fetchPendingManagers = async () => {
      try {
        const pendingManagersResponse = await axios.get(
          `${URL}/api/v1/auth/superAdmin/pending-managers`
        );
        const data = pendingManagersResponse.data.data;
        setPendingManagers(data);

        // Save to session storage
        window.sessionStorage.setItem("pending-managers", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching pending managers:", error);
        setError(
          error.response?.data?.message || "Failed to fetch pending managers"
        );
      }
    };

    const fetchActiveAdmins = async () => {
      try {
        const activeAdminsResponse = await axios.get(
          `${URL}/api/v1/auth/superAdmin/getAllManagers`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const data = activeAdminsResponse.data.data;
        setAdminsData(data);

        // Save to session storage
        window.sessionStorage.setItem("admins-data", JSON.stringify(data));
      } catch (error) {
        console.error("Error fetching active admins:", error);
        setError(
          error.response?.data?.message || "Failed to fetch active admins"
        );
      }
    };

    fetchActiveAdmins();
    fetchPendingManagers();
  }, []);

  // Load data from session storage on mount
  useEffect(() => {
    const storedAdminsData = window.sessionStorage.getItem("admins-data");
    const storedPendingManagers =
      window.sessionStorage.getItem("pending-managers");

    if (storedAdminsData) {
      setAdminsData(JSON.parse(storedAdminsData));
    }
    if (storedPendingManagers) {
      setPendingManagers(JSON.parse(storedPendingManagers));
    }
  }, []);

  const activeAdmins = adminsData.filter((admin) => admin.status === "active");
  const otherAdmins = adminsData.filter(
    (admin) => admin.status !== "active" && admin.status !== ""
  );

  const handleViewAdmin = (adminId) => {
    navigate(`/manageAdmins/viewAdmin/${adminId}`);
  };

  const handleAcceptRequest = async (id) => {
    try {
      const response = await axios.patch(
        `${URL}/api/v1/auth/superAdmin/acceptManager`,
        { managerId: id } // Adjust payload as required
      );

      console.log(`Accepted request ID: ${id}`, response.data);

      // Update the state to remove the accepted manager
      setPendingManagers((prevManagers) =>
        prevManagers.filter((request) => request._id !== id)
      );

      // Update session storage
      window.sessionStorage.setItem(
        "pending-managers",
        JSON.stringify(pendingManagers.filter((request) => request._id !== id))
      );

      // Show success toast
      toast.success("Manager request accepted successfully!");
    } catch (error) {
      console.error("Error accepting request:", error);

      // Show error toast
      toast.error(
        error.response?.data?.message || "Failed to accept manager request."
      );
    }
  };

  const handleDeleteRequest = (id) => {
    console.log(`Deleted request ID: ${id}`);
  };

  const handleSelectListing = (id) => {
    setSelectedListings((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((listingId) => listingId !== id)
        : [...prevSelected, id]
    );
  };

  const handleApproveSelectedListings = () => {
    console.log(`Approved listings: ${selectedListings}`);
    setSelectedListings([]);
  };
  const handleDeleteListing = (id) => {
    console.log(`Deleted listing ID: ${id}`);
    // Here you can filter out the deleted listing or make an API call
  };

  return (
    <div className={styles.manageAdminsWrapper}>
      <h1 className={styles.pageTitle}>Manage Admins</h1>

      {/* Pending Admin Requests Section */}
      <div>
        <h2 className={styles.sectionTitle}>Pending Admin Requests</h2>
        {pendingManagers.length === 0 ? (
          <p className={styles.noRequests}>No pending requests.</p>
        ) : (
          <ul className={styles.requestList}>
            {pendingManagers.map((request) => (
              <li key={request._id} className={styles.requestItem}>
                <Checkbox />
                <div className={styles.requestDetails}>
                  <span className={styles.requestName}>{request.name}</span>
                  <span className={styles.requestEmail}>{request.email}</span>
                </div>
                <div className={styles.requestActions}>
                  <button
                    className={styles.acceptButton}
                    onClick={() => handleAcceptRequest(request._id)}
                  >
                    Accept
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={() => handleDeleteRequest(request._id)}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Listings Approval Requests Section */}
      <div>
        <h2 className={styles.sectionTitle}>Listings Approval Requests</h2>
        {listingsApprovalRequests.length === 0 ? (
          <p className={styles.noRequests}>No listings approval requests.</p>
        ) : (
          <div className={styles.listingsSection}>
            <ul className={styles.listingsList}>
              {listingsApprovalRequests.map((request) => (
                <li key={request.id} className={styles.listingItem}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedListings.includes(request.id)}
                      onChange={() => handleSelectListing(request.id)}
                    />
                    <span>
                      {request.listing} (Owner: {request.owner})
                    </span>
                  </label>
                  <button
                    className={styles.deleteButton}
                    onClick={() => handleDeleteListing(request.id)}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
            {selectedListings.length > 0 && (
              <button
                className={styles.approveButton}
                onClick={handleApproveSelectedListings}
              >
                Approve Selected Listings
              </button>
            )}
          </div>
        )}
      </div>

      {/* Active Admins Section */}
      <div>
        <h2 className={styles.sectionTitle}>Active Admins</h2>
        <div className={styles.adminsGrid}>
          {activeAdmins.map((admin) => (
            <div
              key={admin._id}
              className={styles.adminBox}
              onClick={() => handleViewAdmin(admin._id)}
            >
              <h3>{admin.name}</h3>
              <p>Total Listings: {admin.totalProperties || "none"}</p>
              <p>
                Total Revenue: Rs.{admin.totalRevenue?.toLocaleString() || 0}/-
              </p>
              <p>Active Devices: {admin.activeDevices || "none"}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Other Status. Admins Section */}
      <div>
        <h2 className={styles.sectionTitle}>Admins with Other Statuses</h2>
        <div className={styles.adminsGrid}>
          {otherAdmins ? (
            <>
              {otherAdmins.map((admin) => (
                <div
                  key={admin._id}
                  className={styles.adminBox}
                  onClick={() => handleViewAdmin(admin._id)}
                >
                  <h3>{admin.name}</h3>
                  <p>Total Listings: {admin.totalProperties || "none"}</p>
                  <p>
                    Total Revenue: Rs.
                    {admin.totalRevenue?.toLocaleString() || 0}/-
                  </p>
                  <p>Active Devices: {admin.activeDevices || "none"}</p>
                </div>
              ))}{" "}
            </>
          ) : (
            "no admins with other statuses"
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageAdmins;
