import React from "react";
import styles from "./LoginSignup.module.css";
import { useNavigate } from "react-router-dom";

const LoginSignup = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login"); // Redirect to login page
  };

  const handleSignup = () => {
    navigate("/signup"); // Redirect to signup page
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Welcome!</h2>
      <p className={styles.description}>Please choose an option below:</p>
      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={handleLogin}>
          Login
        </button>
        <button className={styles.button} onClick={handleSignup}>
          Signup
        </button>
      </div>
    </div>
  );
};

export default LoginSignup;
