import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { completeEmailSignIn } from "../../utils/loginUtils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CompleteSignIn = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const emailLink = location.search;
    if (emailLink) {
      completeEmailSignIn(emailLink)
        .then((user) => {
          toast.success("Email sign-in successful!");
          navigate("/dashboard");
        })
        .catch((error) => {
          toast.error("Failed to complete email sign-in. Please try again.");
          navigate("/login");
        });
    }
  }, [location, navigate]);

  return (
    <div>
      <ToastContainer />
      <h2>Completing Sign-In...</h2>
    </div>
  );
};

export default CompleteSignIn;
