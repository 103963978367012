import React, { useState } from "react";
import "./addProperty.css";
import ResidentialBuilding from "./residentialBuilding";

import PropTypes from "prop-types";
const AddProperty = ({
  handleInputChange,
  handleSubmit,
  handleBuildingInputChange,
  handleFacilitiesChange,
  handlePicturesChange,
  handleVideosChange,
  handleRemoveImage,
  formData,
  errorMessage,
  image,
}) => {
  const initialSpecs = formData.specs || {};
  const propertyType = ["Seat", "Room", "Appartment", "Floor", "Building"],
    propertyUnit = ["Seat", "Room", "Appartment", "Floor", "Building"],
    areaUnits = ["Marla", "Kanal", "Acre", "Kila", "Murabba"],
    facilities = [
      "Water",
      "Electricity",
      "Gas",
      "Internet",
      "Cable",
      "Cleaning",
      "Security",
      "Filtered Water",
      "CCTV Cameras",
      "Garage",
      "Porsche",
      "Water Geysers",
      "Basement",
    ];
  return (
    <div className="form-container" id="addPropertyForm">
      <form
        onSubmit={handleSubmit}
        action="/profile"
        method="post"
        encType="multipart/form-data"
      >
        {/* Property Type */}
        <fieldset>
          <legend>Property Type</legend>
          <label>
            What is your property type?
            <select
              name="propertyType"
              value={formData.propertyType}
              onChange={handleInputChange}
            >
              <option value="">Select Property Type</option>
              <option value="commercial">Commercial</option>
              <option value="residential">Residential</option>
            </select>
          </label>
          <br />
        </fieldset>

        {formData.propertyType === "residential" && (
          <fieldset>
            <legend>Rent Structure</legend>
            <label>
              How would you classify your property?
              <select
                name="propertyClassification"
                value={formData.propertyClassification}
                onChange={handleInputChange}
                defaultValue="Select"
              >
                {propertyType
                  .slice()
                  .reverse()
                  .map((element, index) => (
                    <option key={index} value={element}>
                      {element}
                    </option>
                  ))}
              </select>
              <br />
              What smallest unit do you rent out in that property?
              <select
                name="propertyUnit"
                value={formData.propertyUnit}
                onChange={handleInputChange}
              >
                {propertyUnit
                  .slice(
                    0,
                    propertyType.indexOf(formData.propertyClassification) + 1
                  )
                  .map((element, index) => (
                    <option key={index} value={element}>
                      {element}
                    </option>
                  ))}
              </select>
            </label>
          </fieldset>
        )}

        <br />
        {/* To be completed Afterwards */}
        {formData.propertyType === "commercial" && (
          <fieldset>
            <label>
              How would you classify your property?
              <select
                name="propertyClassification"
                value={formData.propertyClassification}
                onChange={handleInputChange}
              >
                {propertyType
                  .slice()
                  .reverse()
                  .map((element, index) => (
                    <option key={index} value={element}>
                      {element}
                    </option>
                  ))}
              </select>
            </label>
          </fieldset>
        )}
        <br />
        {formData.propertyType === "residential" &&
          formData.propertyUnit &&
          formData.propertyUnit === "Building" && (
            <ResidentialBuilding
              formData={formData}
              handleBuildingInputChange={handleBuildingInputChange}
              handleFacilitiesChange={handleFacilitiesChange}
              handlePicturesChange={handlePicturesChange}
              handleVideosChange={handleVideosChange}
              handleRemoveImage={handleRemoveImage}
              areaUnits={areaUnits}
              facilities={facilities}
              errorMessage={errorMessage} // Pass the errorMessage if needed
              image={image}
            />
          )}
        <br />

        {formData.propertyType === "residential" &&
          formData.propertyUnit &&
          formData.propertyUnit === "Floor" && <label></label>}

        {formData.propertyType === "residential" &&
          formData.propertyUnit &&
          formData.propertyUnit === "Appartment" && <label></label>}

        {formData.propertyType === "residential" &&
          formData.propertyUnit &&
          formData.propertyUnit === "Room" && <label></label>}

        {formData.propertyType === "residential" &&
          formData.propertyUnit &&
          formData.propertyUnit === "Seat" && <label></label>}
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

AddProperty.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBuildingInputChange: PropTypes.func.isRequired,
  handleFacilitiesChange: PropTypes.func.isRequired,
  handlePicturesChange: PropTypes.func.isRequired,
  handleVideosChange: PropTypes.func.isRequired,
  handleRemoveImage: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  image: PropTypes.any,
};
export default AddProperty;
