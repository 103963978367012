import React, { useState, useEffect } from "react";
import UpdateProperty from "../../../../components/updateProperty/updateProperty";

export default function PropertyUpdate() {
  return (
    <>
      <UpdateProperty />
    </>
  );
}
