import React from "react";
import { ErrorBoundary } from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }) {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#d1ecf1",
    color: "#721c24",
    padding: "20px",
    borderRadius: "8px",
    border: "1px solid #f5c6cb",
  };

  const headingStyle = {
    fontSize: "2rem",
    marginBottom: "1rem",
  };

  const errorStyle = {
    backgroundColor: "#f1f1f1",
    padding: "10px",
    borderRadius: "4px",
    marginBottom: "1rem",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    fontSize: "1rem",
    color: "#6c757d",
  };

  const buttonStyle = {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "1rem",
    cursor: "pointer",
  };

  const buttonHoverStyle = {
    backgroundColor: "#0056b3",
  };

  const handleMouseOver = (e) => {
    Object.assign(e.target.style, buttonHoverStyle);
  };

  const handleMouseOut = (e) => {
    Object.assign(e.target.style, buttonStyle);
  };

  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>Oops! Something went wrong:</h1>
      <pre style={errorStyle}>{error.message}</pre>
      <button
        style={buttonStyle}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={resetErrorBoundary}
      >
        Try Again
      </button>
    </div>
  );
}

// Function to log errors to the server
async function logErrorToServer(error, errorInfo) {
  try {
    await fetch("/api/log-error", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        error: error.toString(),
        errorInfo,
        timestamp: new Date().toISOString(),
      }),
    });
  } catch (err) {
    console.error("Error logging to server:", err);
  }
}

export function AppErrorBoundary({ children }) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={logErrorToServer} // Logs errors to the server
    >
      {children}
    </ErrorBoundary>
  );
}

export default AppErrorBoundary;
