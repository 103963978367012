import React from "react";
import PropTypes from "prop-types";
import styles from "./loginDialog.module.css";

const LoginDialog = ({ dialogRef, isAuthenticated, onLogoutClick }) => {
  return (
    <div ref={dialogRef} className={styles.dialogMainContainer}>
      <div className={styles.dialogOptions}>
        {isAuthenticated ? (
          <>
            <h3 className={styles.dialogMenu} onClick={onLogoutClick}>
              Logout
            </h3>
          </>
        ) : (
          <>
            <h3 className={styles.loginText}>Log in / Sign up</h3>
          </>
        )}
        <div className={styles.optionsBreak}></div>
        <h3 className={styles.dialogMenu}>Rent your home</h3>
        <h3 className={styles.dialogMenu}>Help Center</h3>
      </div>
    </div>
  );
};

LoginDialog.propTypes = {
  dialogRef: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default LoginDialog;
