import React, { useState, useEffect } from "react";
import styles from "./Payments.module.css"; // Proper CSS Module import

const Payments = () => {
  const [payments, setPayments] = useState({
    rent: [],
    utility: [],
    misc: [],
  });

  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectedPayments, setSelectedPayments] = useState([]);

  // Generate the current month and year for labeling
  const currentMonth = new Date().toLocaleString("default", { month: "long" });
  const currentYear = new Date().getFullYear();
  const currentMonthYear = `${currentMonth} ${currentYear}`;

  useEffect(() => {
    // Dummy data for payments
    const dummyPayments = {
      rent: [
        {
          id: 1,
          amount: 1000,
          dueDate: "2024-12-01",
          paid: false,
        },
        {
          id: 2,
          amount: 950,
          dueDate: "2024-11-15",
          paid: true,
        },
      ],
      utility: [
        {
          id: 1,
          amount: 200,
          dueDate: "2024-12-05",
          paid: false,
        },
      ],
      misc: [
        {
          id: 1,
          amount: 50,
          dueDate: "2024-12-10",
          paid: false,
        },
      ],
    };

    setPayments(dummyPayments);
  }, []);

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handlePaymentSelect = (e, paymentId) => {
    if (e.target.checked) {
      setSelectedPayments([...selectedPayments, paymentId]);
    } else {
      setSelectedPayments(selectedPayments.filter((id) => id !== paymentId));
    }
  };

  const calculateLateFee = (dueDate, amount) => {
    const currentDate = new Date();
    const due = new Date(dueDate);
    if (currentDate > due) {
      return amount * 0.1; // 10% late fee
    }
    return 0;
  };

  const renderPaymentItem = (paymentType, payment) => {
    const lateFee = calculateLateFee(payment.dueDate, payment.amount);
    const totalAmount = payment.amount + lateFee;
    return (
      <div key={payment.id} className={styles.paymentItem}>
        <div className={styles.paymentHeader}>
          <h3>{paymentType} Payment</h3>
        </div>
        <div className={styles.paymentDetails}>
          <p>
            <strong>Amount:</strong> ${payment.amount}
          </p>
          <p>
            <strong>Due Date:</strong>{" "}
            {new Date(payment.dueDate).toLocaleDateString()}
          </p>
          {payment.paid ? (
            <p className={styles.paidStatus}>
              <strong>Status:</strong> Paid
            </p>
          ) : (
            <>
              <p className={styles.pendingStatus}>
                <strong>Status:</strong> Pending
              </p>
              {lateFee > 0 && (
                <p className={styles.lateFee}>
                  <strong>Late Fee (10%):</strong> ${lateFee}
                </p>
              )}
              <p className={styles.totalAmount}>
                <strong>Total:</strong> ${totalAmount}
              </p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.paymentsContainer}>
      <h1 className={styles.pageTitle}>Your Pending Payments</h1>

      {/* Add Payment Method Section */}
      <div className={styles.paymentMethodSection}>
        <h2>Add Payment Method</h2>
        <label htmlFor="paymentMethod" className={styles.formLabel}>
          Payment Method:
        </label>
        <input
          type="text"
          id="paymentMethod"
          value={paymentMethod}
          onChange={handlePaymentMethodChange}
          placeholder="Enter payment method"
          className={styles.inputField}
        />
      </div>

      {/* Pending Payments Table Section */}
      <div className={styles.pendingPaymentsSection}>
        <h2>{currentMonthYear} Pending Payments</h2>
        <table className={styles.paymentTable}>
          <thead>
            <tr>
              <th>Select</th>
              <th>Payment Type</th>
              <th>Amount</th>
              <th>Due Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {[...payments.rent, ...payments.utility, ...payments.misc]
              .filter((payment) => !payment.paid) // Filter out paid payments
              .map((payment) => (
                <tr key={payment.id}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={(e) => handlePaymentSelect(e, payment.id)}
                    />
                  </td>
                  <td>
                    {payment.id <= 2
                      ? "Rent"
                      : payment.id <= 3
                      ? "Utility"
                      : "Miscellaneous"}
                  </td>
                  <td>${payment.amount}</td>
                  <td>{new Date(payment.dueDate).toLocaleDateString()}</td>
                  <td>
                    {payment.paid ? (
                      <span className={styles.paidStatus}>Paid</span>
                    ) : (
                      <span className={styles.pendingStatus}>Pending</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payments;
