import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./calendarComponent.module.css";

const CalendarComponent = () => {
  // State for the selected date range
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  // Clear the selected dates
  const clearDates = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  return (
    <div className={styles.calendarComponentContainer}>
      <h1>Select Dates</h1>
      <p>Add your travel dates for exact pricing</p>
      <div className={styles.calendarContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className={styles.datePickers}>
            <DatePicker
              label="Start Date"
              value={selectedStartDate}
              onChange={(newValue) => setSelectedStartDate(newValue)}
              renderInput={(params) => <input {...params} />}
            />
            <DatePicker
              label="End Date"
              value={selectedEndDate}
              onChange={(newValue) => setSelectedEndDate(newValue)}
              renderInput={(params) => <input {...params} />}
            />
          </div>
        </LocalizationProvider>
      </div>
      <div>
        <button className={styles.clearButton} onClick={clearDates}>
          Clear dates
        </button>
      </div>
    </div>
  );
};

export default CalendarComponent;
