import React, { useState } from "react";
import Papa from "papaparse"; // Import the PapaParse library
import { useNavigate } from "react-router-dom";
import styles from "./automatedAdverts.module.css"; // Make sure to update the CSS file name accordingly

function AutomatedAdverts() {
  const navigate = useNavigate();

  const [messageTemplates, setMessageTemplates] = useState([
    {
      id: 1,
      name: "Welcome Message",
      content: "Hello {{name}}, welcome to our platform!",
    },
    {
      id: 2,
      name: "Rent Reminder",
      content: "Dear {{name}}, your rent is due on {{dueDate}}.",
    },
  ]);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [customMessage, setCustomMessage] = useState("");
  const [csvData, setCsvData] = useState([]);

  // Handle CSV file upload and parsing
  const handleCSVUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          console.log("CSV Data:", result.data);
          setCsvData(result.data);
        },
        header: true, // Assume the first row contains headers
      });
    }
  };

  const handleTemplateSelect = (templateId) => {
    const selected = messageTemplates.find((msg) => msg.id === templateId);
    setSelectedTemplate(selected);
  };

  const handleRecipientChange = (e, recipientType) => {
    if (e.target.checked) {
      setSelectedRecipients((prev) => [...prev, recipientType]);
    } else {
      setSelectedRecipients((prev) =>
        prev.filter((item) => item !== recipientType)
      );
    }
  };

  const handleSendMessage = () => {
    // Logic to send message, either to selected recipients or a custom message
    if (selectedRecipients.length > 0) {
      console.log("Sending message to:", selectedRecipients);
    }
    // Handle CSV and template replacements
  };

  return (
    <div className={styles.automatedAdvertsWrapper}>
      <h1 className={styles.pageTitle}>Automated Advertisements</h1>

      <div className={styles.section}>
        <h2>Select Message Template</h2>
        <select
          onChange={(e) => handleTemplateSelect(parseInt(e.target.value))}
        >
          <option value="">Select a Template</option>
          {messageTemplates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.section}>
        <h2>Custom Message</h2>
        <textarea
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          placeholder="Enter custom message"
        />
      </div>

      <div className={styles.section}>
        <h2>Select Recipients</h2>
        <div>
          <label>
            <input
              type="checkbox"
              onChange={(e) => handleRecipientChange(e, "admins")}
            />
            Admins
          </label>
          <label>
            <input
              type="checkbox"
              onChange={(e) => handleRecipientChange(e, "rentees")}
            />
            Rentees
          </label>
          <label>
            <input
              type="checkbox"
              onChange={(e) => handleRecipientChange(e, "manual")}
            />
            Manually Add
          </label>
        </div>
      </div>

      <div className={styles.section}>
        <h2>Upload CSV</h2>
        <input type="file" accept=".csv" onChange={handleCSVUpload} />
      </div>

      <div className={styles.section}>
        <button
          className={styles.automatedAdvertsButton}
          onClick={handleSendMessage}
        >
          Send Message
        </button>
      </div>
    </div>
  );
}

export default AutomatedAdverts;
