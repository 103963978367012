import React, { useState } from "react";
import styles from "./optionsPicker.module.css";

const OptionsPicker = ({ title, options = [] }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <h2 id={styles.optionPickerTitle}>{title}</h2>
      <div className={styles.optionsSelectContentMainContainer}>
        {options.map((column, columnIndex) => (
          <div key={columnIndex} className={styles.checkboxColumn}>
            {Array.isArray(column) ? (
              column.map((option, index) => (
                <label key={index}>
                  <input type="checkbox" /> {option}
                </label>
              ))
            ) : (
              <p>Invalid column format</p>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default OptionsPicker;
