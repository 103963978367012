import React from "react";
import "./addProperty.css";

const ResidentialBuilding = ({
  formData,
  handleBuildingInputChange,
  handleFacilitiesChange,
  handlePicturesChange,
  handleVideosChange,
  handleRemoveImage,
  areaUnits,
  facilities,
  errorMessage,
  image,
}) => {
  return (
    <fieldset>
      <legend>Property Description</legend>

      {/* Address */}
      <label>
        Type the address of your building:
        <br />
        <input
          name="address"
          placeholder="Enter the address of your building"
          value={formData.specs?.address || ""}
          onChange={handleBuildingInputChange}
          required
        />
      </label>
      <br />

      {/* Area */}
      <label>
        What is the area of your building?
        <br />
        <input
          name="area"
          placeholder="Enter the area of your building"
          type="number"
          min="0"
          value={formData.specs?.area || ""}
          onChange={handleBuildingInputChange}
          required
        />
        <select
          name="unit"
          value={formData.specs?.unit || ""}
          onChange={handleBuildingInputChange}
        >
          {areaUnits.map((unit, index) => (
            <option key={index} value={unit}>
              {unit}
            </option>
          ))}
        </select>
      </label>
      <br />

      {/* Rent and Security */}
      <label>
        What is the per month rent of your building?
        <br />
        <input
          name="rent"
          placeholder="Per month rent"
          type="number"
          min="0"
          value={formData.specs?.rent || ""}
          onChange={handleBuildingInputChange}
          required
        />
      </label>
      <br />
      <label>
        How much advance security should the rentee deposit?
        <br />
        <input
          name="security"
          placeholder="Advance security"
          type="number"
          min="0"
          value={formData.specs?.security || ""}
          onChange={handleBuildingInputChange}
          required
        />
      </label>
      <br />

      {/* Rent Deadline */}
      <label>
        By which date of each month must the rentee deposit rent along with
        other dues such as bills?
        <br />
        <input
          name="rentDeadline"
          placeholder="Rent submission deadline"
          type="number"
          min="0"
          value={formData.specs?.rentDeadline || ""}
          onChange={handleBuildingInputChange}
          required
        />
      </label>
      <br />

      {/* Building Name and Floors */}
      <label>
        Building Name (optional):
        <br />
        <input
          name="buildingName"
          placeholder="Enter building name"
          value={formData.specs?.buildingName || ""}
          onChange={handleBuildingInputChange}
        />
      </label>
      <br />
      <label>
        No of Floors:
        <br />
        <input
          name="floors"
          placeholder="Number of floors"
          type="number"
          min="0"
          value={formData.specs?.floors || ""}
          onChange={handleBuildingInputChange}
          required
        />
      </label>
      <br />

      {/* Facilities */}
      <label>
        Mark the facilities which are available in your building:
        <br />
        {facilities.map((facility, index) => (
          <div key={index} className="checkbox-container">
            <input
              type="checkbox"
              id={facility}
              name={facility}
              value={facility}
              checked={formData.specs?.facilities?.[facility] || false}
              onChange={handleFacilitiesChange}
            />
            <label htmlFor={facility}>{facility}</label>
          </div>
        ))}
      </label>
      <br />

      {/* Furnishing */}
      <label>
        Furnishing:
        <select
          name="furnishing"
          value={formData.specs?.furnishing || ""}
          onChange={handleBuildingInputChange}
        >
          <option value="fullyFurnished">Fully Furnished</option>
          <option value="partiallyFurnished">Partially Furnished</option>
          <option value="notFurnished">Not Furnished</option>
          <option value="greyStructure">Grey Structure</option>
        </select>
      </label>
      <br />

      {/* Pictures */}
      <label>
        Pictures (Max size: 500KB/pic, JPEG or WebP):
        <br />
        <input
          name="picture"
          type="file"
          accept="image/jpeg, image/webp"
          onChange={handlePicturesChange}
          capture="environment"
          multiple
          required
        />
        <div className="image-container">
          {image.map((imgSrc, index) => (
            <div key={index} className="image-item">
              <img src={imgSrc} alt={`Image ${index + 1}`} />
              <button onClick={() => handleRemoveImage(index)}>Remove</button>
            </div>
          ))}
        </div>
      </label>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <br />
    </fieldset>
  );
};

export default ResidentialBuilding;
