import React, { useState } from "react";
import axios from "axios"; // Import Axios
import styles from "./BecomeAdmin.module.css";
import { useAuth } from "../../../../context/AuthContext";
import { URL } from "../../../../route";

const BecomeAdmin = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { authData } = useAuth();

  const handleSubmit = async () => {
    try {
      const response = await axios.patch(
        `${URL}/api/v1/auth/become-admin`,
        { _id: authData.data._id }, // Pass the user ID
        {
          headers: {
            Authorization: `Bearer ${authData.token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Verification submitted successfully!");
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error("Error submitting verification request:", error);
      alert("Failed to submit verification request. Please try again.");
    }
  };

  return (
    <div className={styles.container}>
      {!isSubmitted ? (
        <>
          <p>
            Requested to become Admin:{" "}
            {authData.data.requestingAdmin ? "true" : "false"}
          </p>
          <h2 className={styles.title}>Become an Admin</h2>
          <p className={styles.description}>
            To become an admin, please verify your credentials by clicking the
            button below.
          </p>
          <button onClick={handleSubmit} className={styles.submitButton}>
            Submit Verification
          </button>
        </>
      ) : (
        <p className={styles.thankYouMessage}>
          Thank you! Your verification request has been submitted. Our team will
          review it shortly.
        </p>
      )}
    </div>
  );
};

export default BecomeAdmin;
