import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoBackIcon } from "../../../../components/svgs/rentpal";
import styles from "./ShowAllPhotos.module.css";
import { WidthFull } from "@mui/icons-material";

const ShowAllPhotos = () => {
  const location = useLocation();
  const { pictures } = location.state || {}; // Get images passed from the previous page
  const navigate = useNavigate();

  if (!pictures || pictures.length === 0) {
    return <p>No photos available.</p>;
  }

  return (
    <div className={styles.showAllPhotosContainer}>
      <div className={styles.btnLinkContainer}>
        <div onClick={() => navigate(-1)} className={styles.backButton}>
          <GoBackIcon className={styles.goBackbtn} />
        </div>
        <span>
          <a>Share</a>
          <a>Save</a>
        </span>
      </div>
      <div className={styles.showAllPhotosContent}>
        <h1>Photo Tour</h1>
        <div className={styles.photosGrid}>
          {pictures.map((pic, index) => (
            <img
              key={index}
              src={pic}
              alt={`Property Picture ${index + 1}`}
              className={styles.photo}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ShowAllPhotos;
