import React, { useState, useEffect } from "react";
import { getCode } from "country-list";
import { validateFormData } from "../../utils/formValidator";
import { auth } from "../../firebase.config";
import axios from "axios";
import { URL } from "../../route";
import "./signUp.css";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProgressBar } from "../../components/progressBar/progressBar";

// import PhoneInput from "react-phone-number-input";

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [country, setCountry] = useState("Configuring Location...");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(1);
  const totalSteps = 4;
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [validationErr, setValidationErr] = useState({
    errors: {},
  });

  const [formData, setFormData] = useState({
    name: "Muhammad Ahmad",
    cnic: "3120286260955",
    phone: "03204771882",
    email: "ahmad.slayer.00@gmail.com",
    whatsapp: "923204771882",
    guarantor: "M Ashraf",
    guarantorPhone: "923217523484",
    guarantorCnic: "3120304123032",
    address: {
      street: "84-F, Bilal Town, Raiwind Rd.",
      city: "Lahore",
      country: "Pakistan",
    },
    browserLocation: location,
    picture: null,
    rentPerStudentPerSeat: 0,
    securityDeposit: 0,
    password: "Ff000!@#",
    confirmPassword: "Ff000!@#",
    idCardFront: null,
    idCardBack: null,
    role: 2,
    verificationStatus: {
      phone: false,
      whatsapp: false,
      identity: false,
      id: false,
      address: false,
    },
    requestingAdmin: false,
  });

  const reverseGeocode = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const state = response.data.address.country;
      return state;
    } catch (error) {
      console.error("Error reverse geocoding:", error);
      return null;
    }
  };
  const fetchCountry = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setCountry(
            await reverseGeocode(
              position.coords.latitude,
              position.coords.longitude
            )
          );
          // setLocation({
          //   latitude: position.coords.latitude,
          //   longitude: position.coords.longitude,
          // });
          setLocation(country);
          console.log(country, getCode(country));
        },
        (error) => {
          console.error("Error Code:", error.code);
          console.error("Error Message:", error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  useEffect(() => {
    fetchCountry();
    setValidationErr(validateFormData(formData));
    // console.log(formData);
  }, [formData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name.includes("address")) {
      const [_, field] = name.split(".");
      setFormData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [field]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (event) => {
    const { name } = event.target;
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        [name]: reader.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  const handleRemoveImage = (name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: null,
    }));
  };

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup(formData.phone);
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  function onSignup(phone) {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + phone;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast("OTP sent!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Show OTP input dialog
        const otp = prompt("Enter OTP sent to your phone:");
        if (otp !== null) {
          onOTPVerify(otp);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify(otp) {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);

        // Submit the rest of the form data to the server
        await submitFormData();

        // Redirect to home page
        window.location.href = "/";
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        alert("OTP verification failed");
      });
  }

  const submitFormData = async () => {
    try {
      if (validationErr.isValid) {
        const formDataObj = new FormData();
        Object.keys(formData).forEach((key) => {
          if (formData[key] instanceof File) {
            formDataObj.append(key, formData[key]);
          } else if (
            typeof formData[key] === "object" &&
            formData[key] !== null
          ) {
            formDataObj.append(key, JSON.stringify(formData[key]));
          } else {
            formDataObj.append(key, formData[key]);
          }
        });
        const response = await axios.post(
          `${URL}/api/v1/auth/signup`,
          formDataObj,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("Signup Successful!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.log("Response:", response.data);
      }
    } catch (error) {
      console.error(
        "Error making the request:",
        error.response ? error.response.data : error.message
      );
      toast.error("Signup Failed. Please try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      toast.error(
        `Error: ${
          error.response
            ? JSON.stringify(error.response.data)
            : JSON.stringify(error.message)
        }`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Start submitting

    try {
      await submitFormData();
      console.log("submit clicked");
    } catch (error) {
      console.error("Error in form submission:", error);
    } finally {
      setIsSubmitting(false); // Stop submitting
    }
  };

  const nextStep = () => {
    // Perform validation for current step if necessary
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const formValidationErrors = () => {
    return (
      validationErr.errors &&
      Object.values(validationErr.errors).some((value) => value !== null)
    );
  };
  const renderStep1 = () => (
    <div>
      <fieldset>
        <legend>
          Personal Details <span style={{ color: "red" }}>*</span>
        </legend>
        <label htmlFor="name">Full Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          placeholder="Enter your full name"
          title="Enter your full name as it appears on official documents"
        />

        <label htmlFor="cnic">CNIC (without dashes):</label>
        <input
          type="text"
          id="cnic"
          name="cnic"
          value={formData.cnic}
          onChange={handleInputChange}
          placeholder="XXXXXXXXXXXXX"
          title="Enter your 13-digit CNIC number without any dashes"
        />

        <label htmlFor="email">Email:</label>
        <input
          required
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Enter your email address"
          title="Enter a valid email address for communication"
        />
        <label htmlFor="phone">Phone (with country code):</label>

        <input
          required
          type="text"
          id="phone"
          name="phone"
          placeholder="+1 123 (123) 123"
          // country={country}
          value={formData.phone} // Change from ph to formData.phone
          onChange={handleInputChange}
        />

        <label htmlFor="whatsapp">WhatsApp (with country code):</label>

        <input
          required
          type="text"
          // country={country}
          value={formData.whatsapp} // Change from ph to formData.whatsapp
          onChange={handleInputChange}
          id="whatsapp"
          name="whatsapp"
          placeholder="+1 123 (123) 123"
          title="Enter your WhatsApp number for quick communication"
        />
      </fieldset>
    </div>
  );

  const renderStep2 = () => (
    <div>
      <fieldset>
        <legend>Guarantor Specifics</legend>

        <label htmlFor="guarantor">Guarantor:</label>
        <input
          required
          type="text"
          id="guarantor"
          name="guarantor"
          value={formData.guarantor}
          onChange={handleInputChange}
          placeholder="Enter guarantor's name"
          title="Enter the full name of your guarantor"
        />

        <label htmlFor="guarantorPhone">Guarantor Phone:</label>
        <input
          type="text"
          id="guarantorPhone"
          name="guarantorPhone"
          value={formData.guarantorPhone}
          onChange={handleInputChange}
          placeholder="Enter guarantor's phone number"
          title="Enter the phone number of your guarantor"
        />

        <label htmlFor="guarantorCnic">Guarantor CNIC:</label>
        <input
          type="text"
          id="guarantorCnic"
          name="guarantorCnic"
          value={formData.guarantorCnic}
          onChange={handleInputChange}
          placeholder="Enter guarantor's CNIC number"
          title="Enter the 13-digit CNIC number of your guarantor without any dashes"
        />
      </fieldset>
      <fieldset>
        <legend>Address</legend>
        <label htmlFor="street">Street:</label>
        <input
          type="text"
          id="street"
          name="address.street"
          value={formData.address.street}
          onChange={handleInputChange}
          placeholder="Enter your street address"
          title="Enter your street address"
        />

        <label htmlFor="city">City:</label>
        <input
          type="text"
          id="city"
          name="address.city"
          value={formData.address.city}
          onChange={handleInputChange}
          placeholder="Enter your city"
          title="Enter your city"
        />

        <label htmlFor="country">Country:</label>
        <input
          type="text"
          id="country"
          name="address.country"
          value={formData.address.country}
          onChange={handleInputChange}
          placeholder="Enter your country"
          title="Enter your country"
        />
      </fieldset>
    </div>
  );

  const renderStep3 = () => (
    <div>
      <fieldset>
        <legend>Upload ID Card Pictures</legend>
        <label htmlFor="idCardFront">ID Card Front:</label>
        <input
          value=""
          type="file"
          id="idCardFront"
          name="idCardFront"
          onChange={handleFileChange}
          title="Upload the front picture of your ID card"
        />
        {formData.idCardFront && (
          <div>
            <img
              src={formData.idCardFront}
              alt="ID Card Front"
              style={{ width: "100px" }}
            />
            <button
              type="button"
              onClick={() => handleRemoveImage("idCardFront")}
            >
              Remove
            </button>
          </div>
        )}
        <label htmlFor="idCardBack">ID Card Back:</label>
        <input
          value=""
          type="file"
          id="idCardBack"
          name="idCardBack"
          onChange={handleFileChange}
          title="Upload the back picture of your ID card"
        />
        {formData.idCardBack && (
          <div>
            <img
              src={formData.idCardBack}
              alt="ID Card Back"
              style={{ width: "100px" }}
            />
            <button
              type="button"
              onClick={() => handleRemoveImage("idCardBack")}
            >
              Remove
            </button>
          </div>
        )}
      </fieldset>
    </div>
  );

  const renderStep4 = () => (
    <div>
      <fieldset>
        <legend>Type your password</legend>
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          placeholder="Please type your password"
          onChange={handleInputChange}
        />
        <label htmlFor="confirmPassword">Confirm Password:</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Please re-enter your password"
          value={formData.confirmPassword}
          onChange={handleInputChange}
        />
      </fieldset>
      <div className="terms-checkbox">
        <input
          type="checkbox"
          id="acceptTerms"
          name="acceptTerms"
          checked={acceptTerms}
          onChange={(e) => setAcceptTerms(e.target.checked)}
        />
        <label htmlFor="acceptTerms" style={{ display: "inline" }}>
          I ACCEPT ALL THE TERMS AND CONDITIONS OF RENTPAL.
        </label>
      </div>
    </div>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return renderStep1();
      case 2:
        return renderStep2();
      case 3:
        return renderStep3();
      case 4:
        return renderStep4();
      default:
        return null;
    }
  };

  const progressPercentage = (step / totalSteps) * 100;
  //removing formik
  return (
    <>
      <ToastContainer />
      <ProgressBar
        progressPercentage={progressPercentage}
        backgroundColor={"blue"}
      />
      <div className="error-prompts-container">
        <ul>
          {validationErr?.errors &&
            Object.keys(validationErr.errors)
              .filter((errKey) => validationErr.errors[errKey] !== null)
              .map((errKey) => (
                <li key={errKey}>{validationErr.errors[errKey]}</li>
              ))}
        </ul>
      </div>

      <form className="sign-up-form" onSubmit={handleSubmit}>
        {renderStep()}
        <div className="button-container">
          {step > 1 && (
            <button
              type="button"
              style={{
                backgroundColor: "#f9f9f9",
                color: "blue",
                textDecoration: "underline",
                justifyContent: "left",
                border: "1px solid blue",
              }}
              onClick={prevStep}
            >
              &#x2190; Previous
            </button>
          )}
          {step < totalSteps && (
            <button type="button" onClick={nextStep}>
              Next
            </button>
          )}
          {step === totalSteps && (
            <>
              <button
                type="submit"
                disabled={isSubmitting || formValidationErrors()}
                style={{
                  backgroundColor:
                    isSubmitting || formValidationErrors() ? "#ccc" : "#007bff",
                  color: "#fff",
                  padding: "8px 16px",
                  border: "none",
                  borderRadius: "4px",
                  cursor:
                    isSubmitting || formValidationErrors()
                      ? "not-allowed"
                      : "pointer",
                }}
              >
                {isSubmitting
                  ? "Submitting..."
                  : formValidationErrors()
                  ? "Resolve the mentioned Errors"
                  : "Submit"}
              </button>

              <div id="recaptcha-container"></div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default SignUp;
