import React from "react";
import { ReactComponent as Wifi } from "../../assets/svgs/wifi.svg";
import styles from "./propertyType.module.css";

const PropertyType = () => {
  return (
    <div className={styles.propertyTypeMainContainer}>
      <h1>Property Type</h1>
      <div className={styles.propertyOptionsContainer}>
        <div className={styles.propertyIconContentContainer}>
          <Wifi />
          <h2>House</h2>
        </div>
        <div className={styles.propertyIconContentContainer}>
          <Wifi />
          <h2>Apartment</h2>
        </div>
        <div className={styles.propertyIconContentContainer}>
          <Wifi />
          <h2>Guest House</h2>
        </div>
      </div>
    </div>
  );
};

export default PropertyType;
