import React from "react";
import styles from "./dialogues.module.css";

const BoxSelect = (props) => {
  return (
    <div className={styles.basicBox} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export { BoxSelect };
