import React from "react";
import Navbar from "../navbar/navbar";
// import { Facilities } from "../../components/facilities/facilities";
import styles from "./header.module.css";

const Header = () => {
  return (
    <div className={styles.headerMainContainer}>
      <Navbar />
      {/* <Facilities /> */}
    </div>
  );
};

export default Header;
