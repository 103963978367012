import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./financials.module.css";
import { Line, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement, // Register LineElement for Line charts
} from "chart.js";

// Register the elements needed
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement // Register LineElement (no need to import `Line` directly)
);

const financialsData = {
  revenue: 500000,
  expenses: 300000,
  netProfit: 200000,
  revenueByCategory: {
    subscription: 200000,
    sales: 150000,
    services: 150000,
  },
  expensesByCategory: {
    salaries: 100000,
    utilities: 50000,
    marketing: 40000,
    rent: 30000,
    others: 80000,
  },
  cashFlow: {
    inflow: 500000,
    outflow: 300000,
  },
  revenueTrends: [40000, 45000, 50000, 55000, 60000, 65000],
  expenseTrends: [30000, 35000, 38000, 42000, 46000, 49000],
  admins: [
    { id: 1, name: "Admin 1", status: "active" },
    { id: 2, name: "Admin 2", status: "suspended" },
    { id: 3, name: "Admin 3", status: "active" },
    { id: 4, name: "Admin 4", status: "blocked" },
  ],
};

function Financials() {
  const navigate = useNavigate();
  const chartRef = useRef(null); // Ref for the chart component
  const {
    revenue,
    expenses,
    netProfit,
    revenueByCategory,
    expensesByCategory,
    cashFlow,
    revenueTrends,
    expenseTrends,
    admins,
  } = financialsData;

  // Pie Chart Data for Revenue and Expenses
  const revenueChartData = {
    labels: Object.keys(revenueByCategory),
    datasets: [
      {
        data: Object.values(revenueByCategory),
        backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
      },
    ],
  };

  const expenseChartData = {
    labels: Object.keys(expensesByCategory),
    datasets: [
      {
        data: Object.values(expensesByCategory),
        backgroundColor: [
          "#FF5733",
          "#33FF57",
          "#FFBD33",
          "#3380FF",
          "#C70039",
        ],
      },
    ],
  };

  // Trend Data (Revenue vs Expenses)
  const trendChartData = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Revenue",
        data: revenueTrends,
        borderColor: "#36A2EB",
        fill: false,
        tension: 0.1,
      },
      {
        label: "Expenses",
        data: expenseTrends,
        borderColor: "#FF5733",
        fill: false,
        tension: 0.1,
      },
    ],
  };

  // Reusable Admin Box Component (Hoverable)
  const AdminBox = ({ admin }) => (
    <div
      className={`${styles.adminBox} ${styles[admin.status]}`}
      onClick={() => navigate(`/view-admin/${admin.id}`)}
    >
      <h3>{admin.name}</h3>
      <p>Status: {admin.status}</p>
    </div>
  );

  // Cleanup chart when the component unmounts
  useEffect(() => {
    const chartInstance = chartRef.current && chartRef.current.chartInstance;

    return () => {
      if (chartInstance) {
        chartInstance.destroy(); // Destroy previous chart to avoid canvas reuse error
      }
    };
  }, []);

  return (
    <div className={styles.financialsWrapper}>
      <h1 className={styles.pageTitle}>Financial Overview</h1>

      {/* Admin Section */}
      <div className={styles.adminSection}>
        <h2>Admins</h2>
        <div className={styles.adminBoxContainer}>
          {admins.map((admin) => (
            <AdminBox key={admin.id} admin={admin} />
          ))}
        </div>
      </div>

      {/* Financial Summary */}
      <div className={styles.summarySection}>
        <div className={styles.summaryBox}>
          <h2>Total Revenue</h2>
          <p className={styles.revenue}>${revenue.toLocaleString()}</p>
        </div>
        <div className={styles.summaryBox}>
          <h2>Total Expenses</h2>
          <p className={styles.expenses}>${expenses.toLocaleString()}</p>
        </div>
        <div className={styles.summaryBox}>
          <h2>Net Profit</h2>
          <p className={styles.netProfit}>${netProfit.toLocaleString()}</p>
        </div>
      </div>

      {/* Revenue Breakdown */}
      <div className={styles.chartSection}>
        <h2>Revenue Breakdown</h2>
        <div className={styles.pieChartContainer}>
          <Pie
            data={revenueChartData}
            options={{
              responsive: true,
              plugins: { legend: { position: "top" } },
            }}
          />
        </div>
      </div>

      {/* Expense Breakdown */}
      <div className={styles.chartSection}>
        <h2>Expense Breakdown</h2>
        <div className={styles.pieChartContainer}>
          <Pie
            data={expenseChartData}
            options={{
              responsive: true,
              plugins: { legend: { position: "top" } },
            }}
          />
        </div>
      </div>

      {/* Revenue vs Expenses Trend */}
      <div className={styles.chartSection}>
        <h2>Revenue vs Expenses Trend</h2>
        <div className={styles.trendChartContainer}>
          <Line
            data={trendChartData}
            options={{
              responsive: true,
              plugins: { legend: { position: "top" } },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Financials;
