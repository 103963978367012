import React from "react";
import styles from "./progressBar.module.css";

const ProgressBar = (props) => {
  return (
    <div className={styles.progressBarContainer}>
      <div
        className={styles.progressBar}
        style={{
          width: `${props.progressPercentage || "20"}%`,
          backgroundColor: `${props.backgroundColor || "black"}`,
        }}
      ></div>
    </div>
  );
};

export { ProgressBar };
