import React from "react";
import "./adminDashboard.css";
import { BoxSelect } from "../../../../components/dialogues/dialogues";
import Exclaimation from "../../../../assets/svgs/Exclaimation.svg";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { useState, useMemo } from "react";

function AdminDashboard() {
  const navigate = useNavigate();
  const { authData } = useAuth();
  const [userData, setUserData] = useState(null);

  useMemo(() => {
    if (authData) {
      setUserData(authData);
      // console.log(userData);
    } else {
      const storedUserData = localStorage.getItem("userData");

      if (storedUserData) {
        setUserData(JSON.parse(storedUserData));
      }
    }
  }, [authData]);

  const handleClick = () => {
    navigate("/signUp");
  };

  return (
    <div className="admin-dashboard-main">
      <div className="admin-dashboard-content">
        <h1 className="text-primary">
          Welcome,{" "}
          {userData && userData.data && userData.data.name
            ? userData.data.name
            : "User"}
        </h1>
        <h1 className="text-secondary">
          Guests can reserve your place 24 hours after you publish—here’s how
          to prepare.
        </h1>
        <br></br>
        <br></br>
        <div className="flexbox">
          <BoxSelect onClick={handleClick}>
            <div>
              <div className="text-danger-container">
                <span className="text-normal">Complete important details</span>
              </div>
              <br />
              <div className="text-danger-container">
                <span className="text-danger">Required to publish</span>
                <img src={Exclaimation} alt="Exclamation Mark" />
              </div>
              <br />
              <span className="text-link-normal">Continue</span>
            </div>
          </BoxSelect>
        </div>
      </div>
      <button className="admin-dashboard-button">
        <h1 className="admin-dashboard-button-text">Complete your listing</h1>
      </button>
      {/* <Verification />
        <PropertyManagement /> => CRUD listings
        <ResidentManagement />.....CRUD residents....<ResidentRequests />.... resident chat
        <FinancialManagement />
        <Announcements />
        <AutomatedAdverts />
        <Smart Devices />
         */}
    </div>
  );
}

export default AdminDashboard;

// Managers/admins:
// 1. CRUD operation for residents
// 2. Complete resident profile along with form details and residents status
// 3. Altering the status of residents i.e. dues cleared, dues not cleared etc
// 5. Total available seats and upcoming seats for advanced booking
// 3. Total revenue, expenses and net profit. No. of empty seats, details of reservations and bookings along with the alloted seats number, mattress and cupboard etc
// 4. Give news, announcements about hostel
// 6. Provide feedback/complaint
