import React, { useState, useRef } from "react";
import "./numberConfirmation.css";
import MoreOptionsDialog from "../moreOptionsDialog/moreOptionsDialog";

const NumberConfirmation = ({
  setCurrentDialog,
  setOtp,
  otp,
  handleOtpSubmit,
}) => {
  const otpVerification = /`\d{6}^/.test(otp);
  console.log(otpVerification);

  return (
    <>
      (
      <div className="nmbr-cnfrm-container">
        <div className="nmbr-cnfrm-title-svg">
          <h3 className="nmbr-cnfrm-title">Confirm your number</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="32"
            viewBox="0 0 19 32"
            fill="none"
            className="backword-svg"
            onClick={() => {
              setCurrentDialog("login");
            }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.5395 1.54834C18.9501 1.17891 18.935 0.597502 18.506 0.249744C18.077 -0.0980144 17.3963 -0.0804392 16.9858 0.288999L0.424588 15.1926C0.392458 15.2154 0.361468 15.2402 0.331809 15.2669C0.291755 15.3029 0.255613 15.3412 0.223375 15.3811C-0.108467 15.7506 -0.0691685 16.2798 0.331871 16.6048C0.331924 16.6049 0.331977 16.6049 0.33203 16.6049L16.2029 30.8872C16.6134 31.2567 17.279 31.2567 17.6895 30.8872C18.1 30.5178 18.1 29.9188 17.6895 29.5494L2.55682 15.9313L18.5395 1.54834Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="break-line-nmbr-cnfrm-dialog"></div>
        <div className="field-text-container">
          <h1 className="field-text">
            Enter the code we sent over SMS to 3115472492:
          </h1>
          <div className="code-input-container">
            <div className="input-field-container">
              <input
                maxLength="6"
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
                className="input-field-code-cnfrm"
              ></input>
            </div>
            {otpVerification === false ? (
              <div className="input-field-dashes-container">
                <div className="dashes"></div>
                <div className="dashes"></div>
                <div className="dashes"></div>
                <div className="dashes"></div>
                <div className="dashes"></div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="break-line-nmbr-cnfrm-dialog"></div>
        <div className="link-btn-container">
          <a
            className="option-link"
            onClick={() => {
              setCurrentDialog("moreOptions");
            }}
          >
            more options
          </a>
          <button className="nmbr-cnfrm-btn" onClick={handleOtpSubmit}>
            Continue
          </button>
        </div>
      </div>
      )
    </>
  );
};

export default NumberConfirmation;
