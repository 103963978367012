import React from "react";
import styles from "./crashReports.module.css";

const CrashReports = () => {
  const crashReports = [
    {
      id: 1,
      user: "John Doe",
      timestamp: "2024-12-01 14:32:00",
      description: "App crashed while saving data.",
      status: "Resolved",
    },
    {
      id: 2,
      user: "Jane Smith",
      timestamp: "2024-12-02 10:20:00",
      description: "Unexpected logout during session.",
      status: "Pending",
    },
    {
      id: 3,
      user: "Mike Johnson",
      timestamp: "2024-12-03 09:15:00",
      description: "Error while uploading a file.",
      status: "Investigating",
    },
  ];

  return (
    <div className={styles.crashReportsPage}>
      <h1 className={styles.pageTitle}>Crash Reports</h1>
      <table className={styles.crashTable}>
        <thead>
          <tr>
            <th>ID</th>
            <th>User</th>
            <th>Timestamp</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {crashReports.map((report) => (
            <tr key={report.id} className={styles.tableRow}>
              <td>{report.id}</td>
              <td>{report.user}</td>
              <td>{report.timestamp}</td>
              <td>{report.description}</td>
              <td
                className={`${styles.status} ${
                  styles[`status-${report.status.toLowerCase()}`]
                }`}
              >
                {report.status}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CrashReports;
