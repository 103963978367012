import React, { useState } from "react";
import styles from "./placeType.module.css";

const PlaceType = () => {
  const [selected, setSelected] = useState("Any type");

  return (
    <div className={styles.placeTypeMainContainer}>
      <h1>PlaceType</h1>
      <div className={styles.placeTypeButtonsContainer}>
        <div className={styles.buttonsTypeContainer}>
          <button
            className={`${styles.buttonType} ${
              selected === "Any type" ? styles.selected : ""
            }`}
            onClick={() => setSelected("Any type")}
          >
            <span>Any type</span>
          </button>
          <button
            className={`${styles.buttonType} ${
              selected === "Room" ? styles.selected : ""
            }`}
            onClick={() => setSelected("Room")}
          >
            <span>Room</span>
          </button>
          <button
            className={`${styles.buttonType} ${
              selected === "Entire home" ? styles.selected : ""
            }`}
            onClick={() => setSelected("Entire home")}
          >
            <span>Entire home</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceType;
