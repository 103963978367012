import { ReactComponent as Wifi } from "../../assets/svgs/wifi.svg";
import { ReactComponent as Parking } from "../../assets/svgs/parking.svg";
import { ReactComponent as Locker } from "../../assets/svgs/locker.svg";
import { ReactComponent as Security } from "../../assets/svgs/security.svg";
import { ReactComponent as Mess } from "../../assets/svgs/mess.svg";
import { ReactComponent as Bedminton } from "../../assets/svgs/bedminton.svg";
import { ReactComponent as Janitory } from "../../assets/svgs/janitory.svg";
import { ReactComponent as Mattress } from "../../assets/svgs/mattress.svg";
import { ReactComponent as Rooms } from "../../assets/svgs/rooms.svg";

export const facilitiesList = [
  {
    icon: <Wifi />,
    label: "Free Wifi",
  },
  {
    icon: <Parking />,
    label: "Spacious Parking",
  },
  {
    icon: <Locker />,
    label: "Separate Locker",
  },
  {
    icon: <Security />,
    label: "Security",
  },
  {
    icon: <Mess />,
    label: "Mess Service",
  },
  {
    icon: <Bedminton />,
    label: "Bedminton Court",
  },
  {
    icon: <Janitory />,
    label: "Janitory",
  },
  {
    icon: <Mattress />,
    label: "Mattress",
  },
  {
    icon: <Rooms />,
    label: "Shared Rooms",
  },
  {
    icon: <Parking />,
    label: "Spacious Parking",
  },
  {
    icon: <Wifi />,
    label: "Free Wifi",
  },
  {
    icon: <Parking />,
    label: "Spacious Parking",
  },
  {
    icon: <Locker />,
    label: "Separate Locker",
  },
  {
    icon: <Security />,
    label: "Security",
  },
  {
    icon: <Mess />,
    label: "Mess Service",
  },
  {
    icon: <Bedminton />,
    label: "Bedminton Court",
  },
  {
    icon: <Janitory />,
    label: "Janitory",
  },
  {
    icon: <Mattress />,
    label: "Mattress",
  },
  {
    icon: <Rooms />,
    label: "Shared Rooms",
  },
  {
    icon: <Parking />,
    label: "Spacious Parking",
  },
];
