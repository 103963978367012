import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import styles from "./listings.module.css";

const Listings = () => {
  const { authData } = useAuth();
  const navigate = useNavigate();

  // Mock data for listings (replace with API calls or context data)
  const completedListings = [
    {
      id: 1,
      title: "Listing 1",
      totalRevenue: "$12,000",
      totalRentableUnits: 10,
      totalVacantUnits: 2,
      unitsToBeVacant: 1,
      syncedSmartDevices: 3,
    },
    {
      id: 2,
      title: "Listing 2",
      totalRevenue: "$8,500",
      totalRentableUnits: 8,
      totalVacantUnits: 1,
      unitsToBeVacant: 0,
      syncedSmartDevices: 5,
    },
  ];

  const unfinishedListings = [
    {
      id: 3,
      title: "Listing 3",
      totalRevenue: "$0",
      totalRentableUnits: 5,
      totalVacantUnits: 0,
      unitsToBeVacant: 0,
      syncedSmartDevices: 2,
    },
  ];

  const pendingApprovalListings = [
    {
      id: 4,
      title: "Listing 4",
      totalRevenue: "$7,300",
      totalRentableUnits: 6,
      totalVacantUnits: 1,
      unitsToBeVacant: 1,
      syncedSmartDevices: 4,
    },
  ];

  const renderListings = (listings) =>
    listings.map((listing) => (
      <li
        key={listing.id}
        className={styles.listingItem}
        onClick={() =>
          navigate(`/dashboard/listings/${authData.data.uniqueId}/add-listings`)
        }
      >
        <div className={styles.listingTitle}>{listing.title}</div>
        <div className={styles.listingData}>
          Total Revenue: {listing.totalRevenue}
        </div>
        <div className={styles.listingData}>
          Total Rentable Units: {listing.totalRentableUnits}
        </div>
        <div className={styles.listingData}>
          Total Vacant Units: {listing.totalVacantUnits}
        </div>
        <div className={styles.listingData}>
          Units to Be Vacant: {listing.unitsToBeVacant}
        </div>
        <div className={styles.listingData}>
          Synced Smart Devices: {listing.syncedSmartDevices}
        </div>
      </li>
    ));

  return (
    <div className={styles.container}>
      <button
        className={styles.addButton}
        onClick={() => {
          navigate(
            `/dashboard/listings/${authData.data.uniqueId}/add-listings`
          );
        }}
      >
        Add Listings
      </button>

      {/* Completed Listings Section */}
      <div className={styles.listingsContainer}>
        <h2>My Listings</h2>
        {completedListings.length > 0 ? (
          <ul className={styles.listings}>
            {renderListings(completedListings)}
          </ul>
        ) : (
          <div className={styles.noListings}>
            No completed listings available
          </div>
        )}
      </div>

      {/* Unfinished Listings Section */}
      <div className={styles.listingsContainer}>
        <h2>Unfinished Listings</h2>
        {unfinishedListings.length > 0 ? (
          <ul className={styles.listings}>
            {renderListings(unfinishedListings)}
          </ul>
        ) : (
          <div className={styles.noListings}>
            No unfinished listings available
          </div>
        )}
      </div>

      {/* Listings Pending Approval Section */}
      <div className={styles.listingsContainer}>
        <h2>Listings Pending Approval</h2>
        {pendingApprovalListings.length > 0 ? (
          <ul className={styles.listings}>
            {renderListings(pendingApprovalListings)}
          </ul>
        ) : (
          <div className={styles.noListings}>No listings pending approval</div>
        )}
      </div>
    </div>
  );
};

export default Listings;
