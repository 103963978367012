import React, { useState, useEffect } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { URL } from "../../../../../route";
import propertyFormData from "../../../../../staticData/addListingsData";
import styles from "./addListings.module.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../../../../context/AuthContext";
const FormikValuesDebug = ({ values, errors, touched }) => (
  <pre style={{ background: "#f6f8fa", padding: "1rem", marginTop: "1rem" }}>
    <strong>Formik Values</strong>
    <code>{JSON.stringify(values, null, 2)}</code>
    <br />
    <strong>Formik Errors</strong>
    <code>{JSON.stringify(errors, null, 2)}</code>
    <br />
    <strong>Formik Touched</strong>
    <code>{JSON.stringify(touched, null, 2)}</code>
  </pre>
);

const PropertyForm = () => {
  const [step, setStep] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [buildingStructure, setBuildingStructure] = useState([]);
  const [rentingType, setRentingType] = useState("");
  const { authData } = useAuth();
  const user = authData.data;
  // useEffect(() => {
  //   console.log("Selected renting type:", user);
  // }, []);

  const handleBuildingStructure = (values) => {
    const totalFloors = parseInt(values.totalFloors, 10);
    const basementFloors = parseInt(values.basementFloors, 10);

    // Ensure values are valid
    if (isNaN(totalFloors) || isNaN(basementFloors)) {
      console.error("Invalid input for totalFloors or basementFloors");
      return;
    }

    const structure = [];
    for (let i = basementFloors; i >= 1; i--) {
      structure.push({ floorLabel: `Basement ${i}`, roomInfo: [] });
    }
    structure.push({ floorLabel: "Ground Floor", roomInfo: [] });
    for (let i = 1; i <= totalFloors - basementFloors - 1; i++) {
      structure.push({ floorLabel: `Floor ${i}`, roomInfo: [] });
    }

    setBuildingStructure(structure);
  };
  const calculateTotals = (values) => {
    let totalUnits = 0;
    let totalRevenue = 0;

    // Check if rentingType is "Entire Building"
    if (rentingType === "Entire Building") {
      // Assume there's a field in values for the rent/sale price
      const rentPrice = parseFloat(values.rentPrice) || 0; // Get the rent/sale price
      totalUnits = 1; // Only one unit for the entire building
      totalRevenue = rentPrice; // Total revenue is the rent/sale price
    } else {
      values.buildingFloors.forEach((floor) => {
        if (rentingType === "By Floors") {
          totalUnits += 1;
          totalRevenue += parseFloat(floor.roomRent || 0);
        } else if (rentingType === "By Rooms") {
          floor.roomInfo.forEach((room) => {
            totalUnits += 1;
            totalRevenue += parseFloat(room.roomRent || 0);
          });
        } else if (rentingType === "By Seats") {
          floor.roomInfo.forEach((room) => {
            totalUnits += parseInt(room.accomodationCapacity || 0, 10);
            totalRevenue +=
              parseFloat(room.roomRent || 0) * (room.accomodationCapacity || 1);
          });
        }
      });
    }

    return { totalUnits, totalRevenue };
  };

  const totalSteps = 8;
  const handleNext = (values) => {
    // Calculate total steps based on current rentingType
    const totalSteps =
      8 +
      (rentingType === "Entire Building" ? 0 : buildingStructure.length + 1);

    if (step === 2) {
      if (!values.totalFloors || !values.basementFloors) {
        console.warn("Please fill in the total floors and basement floors.");
        return;
      }
      handleBuildingStructure(values);
      setStep(3);
      return;
    }

    if (step === 3) {
      if (!rentingType) {
        console.warn("Please select a renting type before proceeding.");
        return;
      }
      setStep(4);
      return;
    }

    // Move to the next step
    setStep((prev) => Math.min(prev + 1, totalSteps - 1));
  };

  const handlePrev = () => {
    setStep((prev) => Math.max(prev - 1, 0));
  };

  const handleSubmit = async (values) => {
    try {
      console.log(values);
      const response = await axios.post(
        `${URL}/api/v1/auth/admin/addListings`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log("Form submitted successfully:", response.data);
        toast.success("Form submitted successfully!"); // Success toast
        // Handle success (e.g., redirect or reset the form)
      } else {
        console.error("Form submission failed:", response.statusText);
        toast.error("Form submission failed: " + response.statusText); // Error toast
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error("Error during form submission: " + error.message); // Error toast
    }
  };

  const renderSection = (sectionTitle) => {
    const sectionData = propertyFormData
      .find((data) => data.category === selectedCategory)
      ?.steps.find((step) => step.title === sectionTitle);

    if (!sectionData) return null;

    return (
      <div>
        <h3>{sectionTitle}</h3>
        {sectionData.fields.map((field, index) => {
          if (field.type === "checkbox") {
            // Map checkboxes to form fields that store selected values as arrays
            return (
              <div key={index}>
                <label>
                  <Field
                    type="checkbox"
                    name={
                      sectionTitle.toLowerCase() === "additional features"
                        ? "additionalFeatures"
                        : sectionTitle.toLowerCase()
                    } // Bind to section name (e.g., "amenities", "utilities")
                    value={
                      field.label === "additional features"
                        ? "additionalFeatures"
                        : field.label
                    } // Store the label as the value
                  />
                  {field.label}
                </label>
              </div>
            );
          }

          if (field.type === "select") {
            return (
              <div key={index}>
                <label>{field.label}</label>
                <Field as="select" name={field.label}>
                  <option value="">Select {field.label}</option>
                  {field.options.map((option, idx) => (
                    <option key={idx} value={option}>
                      {option}
                    </option>
                  ))}
                </Field>
              </div>
            );
          }

          if (field.type === "text" || field.type === "number") {
            return (
              <div key={index}>
                <label>{field.label}</label>
                <Field type={field.type} name={field.label} className="input" />
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  };

  return (
    <>
      {" "}
      <ToastContainer />
      <Formik
        initialValues={{
          uniqueId: user?.uniqueId || "",
          email: user?.email || "",
          category: "",
          subcategory: "",
          amenities: [],
          utilities: [],
          additionalFeatures: [],
          facilities: [],
          rentingType: "",
          totalFloors: 0,
          basementFloors: 0,
          buildingFloors: [
            {
              numberOfRooms: 0, // Add this to keep track of number of rooms
              roomInfo: [
                {
                  roomNumber: "",
                  roomSize: "",
                  accomodationCapacity: "",
                  roomRent: "",
                  roomImages: [],
                },
              ],
            },
          ],
          rentableUnits: () => {},
          rentPrice: "",
          buildingImages: [],
        }}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form className={styles.formContainer}>
            {step === 0 && (
              <div>
                <label className={styles.label}>Select Category</label>
                <Field
                  as="select"
                  name="category"
                  onChange={(e) => {
                    // Update Formik's internal state automatically
                    setFieldValue("category", e.target.value); // This updates the category in Formik's state

                    // Update the local state (optional)
                    setSelectedCategory(e.target.value);

                    // Move to the next step
                    setStep(1);
                  }}
                  className={styles.select}
                >
                  <option value="">Select category</option>
                  {[
                    ...new Set(propertyFormData.map((data) => data.category)),
                  ].map((category, index) => (
                    <option key={index} value={category ? category : ""}>
                      {category}
                    </option>
                  ))}
                </Field>
              </div>
            )}

            {step === 1 && selectedCategory && (
              <div>
                <label className={styles.label}>Select Subcategory</label>
                <Field
                  as="select"
                  name="subcategory"
                  onChange={(e) => {
                    // Update Formik's internal state automatically
                    setFieldValue("subcategory", e.target.value); // This updates the category in Formik's state

                    // Update the local state (optional)
                    setSelectedSubcategory(e.target.value);

                    // Move to the next step
                    setStep(2);
                  }}
                  className={styles.select}
                >
                  <option value="">Select subcategory</option>
                  {propertyFormData
                    .filter((data) => data.category === selectedCategory)
                    .map((data, index) => (
                      <option
                        key={`${data.subcategory}-${index}`}
                        value={data.subcategory ? data.subcategory : ""}
                      >
                        {data.subcategory}
                      </option>
                    ))}
                </Field>
              </div>
            )}

            {step === 2 && (
              <div>
                <h3 className={styles.title}>Building Structure Information</h3>
                <div>
                  <label className={styles.label}>
                    Any basement in the building?
                  </label>
                  <Field
                    name="basementFloors"
                    type="number"
                    min="0"
                    className={styles.input}
                  />
                </div>
                <div>
                  <label className={styles.label}>
                    Total floors in the building (including basement)
                  </label>
                  <Field
                    name="totalFloors"
                    type="number"
                    min="1"
                    className={styles.input}
                  />
                </div>
              </div>
            )}

            {step === 3 && (
              <div>
                <h3 className={styles.title}>Property Type Selection</h3>
                {propertyFormData
                  .find((data) => data.category === selectedCategory)
                  ?.steps.find(
                    (step) => step.title === "Property Type Selection"
                  )
                  ?.fields.map((field) => (
                    <div key={field.label}>
                      <label className={styles.label}>{field.label}</label>
                      <Field
                        as="select"
                        name="rentingType"
                        onChange={(e) => {
                          const value = e.target.value ? e.target.value : "";
                          // Set the renting type
                          setRentingType(value);
                          // Update Formik's `values` directly
                          setFieldValue("rentingType", value);
                          // Only call handleNext if a valid renting type is selected
                          if (value) {
                            // console.log({ ...values, rentingType: value });
                            handleNext(values); // Pass the new value directly to handleNext
                          }
                        }}
                        className={styles.select}
                      >
                        <option value="">Select an option</option>
                        {field.options.map((option) => (
                          <option key={option} value={option ? option : ""}>
                            {option}
                          </option>
                        ))}
                      </Field>
                    </div>
                  ))}
              </div>
            )}

            {/*Main step of. form*/}
            {step === 4 && rentingType && (
              <div>
                {rentingType === "Entire Building" && (
                  <>
                    <h1>Entire Building selected</h1>
                    {renderSection("Basic Information")}
                    {renderSection("Amenities")}
                    {renderSection("Utilities")}
                    {renderSection("Additional Features")}
                  </>
                )}

                {(rentingType === "By Floors" ||
                  rentingType === "By Rooms" ||
                  rentingType === "By Seats") && (
                  <div>
                    <h3 className={styles.title}>
                      Building Division (By Floors)
                    </h3>

                    {/* Automatically calculate and display the floors based on te building structure */}
                    <FieldArray
                      name="buildingFloors"
                      render={(arrayHelpers) => {
                        // Building structure calculation
                        const basementFloors = values.basementFloors || 0;
                        const totalFloors = values.totalFloors || 0;
                        const aboveGroundFloors =
                          totalFloors - basementFloors - 1;

                        // Generate building structure
                        const buildingStructure = [];
                        for (let i = basementFloors; i > 0; i--) {
                          buildingStructure.push(`B${i}`);
                        }
                        buildingStructure.push("G");
                        for (let i = 1; i <= aboveGroundFloors; i++) {
                          buildingStructure.push(`${i}`);
                        }

                        return (
                          <div>
                            {buildingStructure.map((floor, index) => (
                              <div key={index}>
                                <h4>{`Floor ${floor}`}</h4>

                                {/* Number of Rooms on Floor */}
                                <label className={styles.label}>
                                  Number of Rooms on Floor {floor}
                                </label>
                                <Field
                                  name={`buildingFloors[${index}].numberOfRooms`}
                                  type="number"
                                  min="1"
                                  className={styles.input}
                                  onChange={(e) => {
                                    const numberOfRooms = parseInt(
                                      e.target.value,
                                      10
                                    );

                                    // Ensure it's a valid number
                                    if (!isNaN(numberOfRooms)) {
                                      // Set the room array size dynamically based on the number of rooms specified
                                      const currentRooms =
                                        values.buildingFloors[index]
                                          ?.roomInfo || [];

                                      if (numberOfRooms > currentRooms.length) {
                                        // Add more rooms if numberOfRooms is greater
                                        for (
                                          let i = currentRooms.length;
                                          i < numberOfRooms;
                                          i++
                                        ) {
                                          arrayHelpers.replace(index, {
                                            ...values.buildingFloors[index],
                                            roomInfo: [
                                              ...currentRooms,
                                              { roomNumber: "", roomSize: 0 },
                                            ],
                                          });
                                        }
                                      } else if (
                                        numberOfRooms < currentRooms.length
                                      ) {
                                        // Remove excess rooms if numberOfRooms is less
                                        arrayHelpers.replace(index, {
                                          ...values.buildingFloors[index],
                                          roomInfo: currentRooms.slice(
                                            0,
                                            numberOfRooms
                                          ),
                                        });
                                      }
                                    }
                                  }}
                                />

                                {/* Display the rooms */}
                                {values.buildingFloors[index] &&
                                values.buildingFloors[index].roomInfo
                                  ? values.buildingFloors[index].roomInfo.map(
                                      (room, roomIndex) => (
                                        <div key={roomIndex}>
                                          <label className={styles.label}>
                                            Room: {roomIndex + 1}
                                          </label>
                                          <Field
                                            name={`buildingFloors[${index}].roomInfo[${roomIndex}].roomNumber`}
                                            type="text"
                                            className={styles.input}
                                          />
                                          <label className={styles.label}>
                                            Room Size (sq. ft.)
                                          </label>
                                          <Field
                                            name={`buildingFloors[${index}].roomInfo[${roomIndex}].roomSize`}
                                            type="number"
                                            className={styles.input}
                                          />
                                          <label className={styles.label}>
                                            Room Accomodation Capacity
                                          </label>
                                          <Field
                                            name={`buildingFloors[${index}].roomInfo[${roomIndex}].accomodationCapacity`}
                                            type="number"
                                            className={styles.input}
                                          />

                                          {rentingType === "By Floors" && (
                                            <>
                                              <label className={styles.label}>
                                                Floor Rent
                                              </label>
                                              <Field
                                                name={`buildingFloors[${index}].roomRent`}
                                                type="number"
                                                className={styles.input}
                                              />
                                            </>
                                          )}
                                          {rentingType === "By Rooms" && (
                                            <>
                                              <label className={styles.label}>
                                                Room Rent
                                              </label>
                                              <Field
                                                name={`buildingFloors[${index}].roomInfo[${roomIndex}].roomRent`}
                                                type="number"
                                                className={styles.input}
                                              />
                                            </>
                                          )}
                                          {rentingType === "By Seats" && (
                                            <>
                                              <label className={styles.label}>
                                                Seat Rent
                                              </label>
                                              <Field
                                                name={`buildingFloors[${index}].roomInfo[${roomIndex}].roomRent`}
                                                type="number"
                                                className={styles.input}
                                              />
                                            </>
                                          )}
                                        </div>
                                      )
                                    )
                                  : null}

                                {/* Button to add more rooms */}
                                <button
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.replace(index, {
                                      ...values.buildingFloors[index],
                                      roomInfo: [
                                        ...values.buildingFloors[index]
                                          .roomInfo,
                                        { roomNumber: "", roomSize: 0 },
                                      ],
                                    })
                                  }
                                >
                                  Add Room
                                </button>
                              </div>
                            ))}
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            )}

            {step === 5 && (
              <div>
                <h3 className={styles.title}>
                  Rentable Units & Revenue Summary
                </h3>
                {(() => {
                  const { totalUnits, totalRevenue } = calculateTotals(values);
                  return (
                    <div>
                      <p>Total Rentable Units: {totalUnits}</p>
                      <p>Total Monthly Revenue: ${totalRevenue}</p>
                    </div>
                  );
                })()}
              </div>
            )}

            {step === 6 && (
              <div>
                <h3 className={styles.title}>
                  {values.rentingType === "Entire Building"
                    ? "Upload Photos for the Entire Building"
                    : `Upload Photos for Each Unit ${values.rentingType}`}
                </h3>

                {values.rentingType === "Entire Building" ? (
                  <div>
                    <label className={styles.label}>
                      Upload Building Images
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      multiple
                      className={styles.input}
                      onChange={(e) => {
                        const files = Array.from(e.target.files);
                        const newImages = files.map((file) => {
                          return new Promise((resolve) => {
                            const reader = new FileReader();
                            reader.onload = (upload) =>
                              resolve(upload.target.result);
                            reader.readAsDataURL(file);
                          });
                        });

                        Promise.all(newImages).then((imageData) => {
                          setFieldValue("buildingImages", [
                            ...(values.buildingImages || []),
                            ...imageData,
                          ]);
                        });
                      }}
                    />

                    {/* Display uploaded image previews for the entire building */}
                    {values.buildingImages &&
                      values.buildingImages.length > 0 && (
                        <div className={styles.imagePreview}>
                          {values.buildingImages.map((imgSrc, imgIndex) => (
                            <div
                              key={imgIndex}
                              className={styles.imageContainer}
                            >
                              <img
                                src={imgSrc}
                                alt={`Building ${imgIndex + 1}`}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  margin: "5px",
                                }}
                              />
                              <button
                                type="button"
                                className={styles.removeButton}
                                onClick={() => {
                                  const newImages =
                                    values.buildingImages.filter(
                                      (_, index) => index !== imgIndex
                                    );
                                  setFieldValue("buildingImages", newImages);
                                }}
                              >
                                Remove Image
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                ) : (
                  <FieldArray
                    name="buildingFloors"
                    render={(arrayHelpers) => (
                      <div>
                        {values.buildingFloors.map((floor, floorIndex) => (
                          <div key={floorIndex}>
                            <h4>Floor {floorIndex + 1}</h4>
                            {floor.roomInfo &&
                              floor.roomInfo.map((room, roomIndex) => (
                                <div key={roomIndex}>
                                  <h5>Room {roomIndex + 1}</h5>
                                  <label className={styles.label}>
                                    Upload Room Images
                                  </label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    multiple
                                    className={styles.input}
                                    onChange={(e) => {
                                      const files = Array.from(e.target.files);
                                      const newImages = files.map((file) => {
                                        return new Promise((resolve) => {
                                          const reader = new FileReader();
                                          reader.onload = (upload) =>
                                            resolve(upload.target.result);
                                          reader.readAsDataURL(file);
                                        });
                                      });

                                      Promise.all(newImages).then(
                                        (imageData) => {
                                          const updatedRoomInfo = [
                                            ...floor.roomInfo,
                                          ];
                                          updatedRoomInfo[roomIndex] = {
                                            ...updatedRoomInfo[roomIndex],
                                            roomImages: [
                                              ...(updatedRoomInfo[roomIndex]
                                                .roomImages || []),
                                              ...imageData,
                                            ],
                                          };

                                          const updatedBuildingFloors = [
                                            ...values.buildingFloors,
                                          ];
                                          updatedBuildingFloors[floorIndex] = {
                                            ...updatedBuildingFloors[
                                              floorIndex
                                            ],
                                            roomInfo: updatedRoomInfo,
                                          };

                                          setFieldValue(
                                            "buildingFloors",
                                            updatedBuildingFloors
                                          );
                                        }
                                      );
                                    }}
                                  />

                                  {/* Display uploaded image previews for each room */}
                                  {room.roomImages &&
                                    room.roomImages.length > 0 && (
                                      <div className={styles.imagePreview}>
                                        {room.roomImages.map(
                                          (imgSrc, imgIndex) => (
                                            <div
                                              key={imgIndex}
                                              className={styles.imageContainer}
                                            >
                                              <img
                                                src={imgSrc}
                                                alt={`Room ${
                                                  roomIndex + 1
                                                }, View ${imgIndex + 1}`}
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  margin: "5px",
                                                }}
                                              />

                                              <button
                                                type="button"
                                                className={styles.removeButton}
                                                onClick={() => {
                                                  const updatedRoomImages =
                                                    room.roomImages.filter(
                                                      (_, index) =>
                                                        index !== imgIndex
                                                    );

                                                  const updatedRoomInfo = [
                                                    ...floor.roomInfo,
                                                  ];
                                                  updatedRoomInfo[roomIndex] = {
                                                    ...updatedRoomInfo[
                                                      roomIndex
                                                    ],
                                                    roomImages:
                                                      updatedRoomImages,
                                                  };

                                                  const updatedBuildingFloors =
                                                    [...values.buildingFloors];
                                                  updatedBuildingFloors[
                                                    floorIndex
                                                  ] = {
                                                    ...updatedBuildingFloors[
                                                      floorIndex
                                                    ],
                                                    roomInfo: updatedRoomInfo,
                                                  };

                                                  setFieldValue(
                                                    "buildingFloors",
                                                    updatedBuildingFloors
                                                  );
                                                }}
                                              >
                                                Remove Image
                                              </button>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )}
                                </div>
                              ))}
                          </div>
                        ))}
                      </div>
                    )}
                  />
                )}
              </div>
            )}

            {step === 7 && (
              <div>
                <h3 className={styles.title}>Review Your Listing</h3>

                {/* Display Basic Information */}
                <div>
                  <h4>Basic Information</h4>
                  <p>Name: {values.name || "N/A"}</p>
                  <p>Email: {values.email || "N/A"}</p>
                </div>

                {/* Display Building Floors and Rooms */}
                <div>
                  <h4>Building Details</h4>
                  {values.buildingFloors && values.buildingFloors.length > 0 ? (
                    values.buildingFloors.map((floor, floorIndex) => (
                      <div key={floorIndex}>
                        <h5>Floor {floorIndex + 1}</h5>
                        {floor.roomInfo && floor.roomInfo.length > 0 ? (
                          floor.roomInfo.map((room, roomIndex) => (
                            <div key={roomIndex}>
                              <h6>Room {roomIndex + 1}</h6>
                              <p>Room Number: {room.roomNumber || "N/A"}</p>
                              <p>Room Size: {room.roomSize || "N/A"} sq. ft.</p>
                              <p>
                                Accommodation Capacity:{" "}
                                {room.accomodationCapacity || "N/A"}
                              </p>
                              <p>Room Rent: ${room.roomRent || "N/A"}</p>

                              {/* Display Uploaded Images */}
                              {room.roomImages && room.roomImages.length > 0 ? (
                                <div className={styles.imagePreview}>
                                  <h6>Uploaded Images:</h6>
                                  {room.roomImages.map((imgSrc, imgIndex) => (
                                    <img
                                      key={imgIndex}
                                      src={imgSrc}
                                      alt={`Room ${roomIndex + 1}, View ${
                                        imgIndex + 1
                                      }`}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        margin: "5px",
                                      }}
                                    />
                                  ))}
                                </div>
                              ) : (
                                <p>No images uploaded</p>
                              )}
                            </div>
                          ))
                        ) : (
                          <p>No rooms added on this floor</p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No floors added</p>
                  )}
                </div>
              </div>
            )}

            <div>
              {step > 0 && (
                <button
                  type="button"
                  onClick={handlePrev}
                  className={styles.button}
                >
                  Previous
                </button>
              )}
              {step < totalSteps - 1 ? ( // Render "Next" until final step
                <button
                  type="button"
                  onClick={() => handleNext(values)}
                  className={styles.button}
                >
                  Next
                </button>
              ) : (
                <button type="submit" className={styles.button}>
                  {" "}
                  {/* "Submit" on final step */}
                  Submit
                </button>
              )}
            </div>
            {/* <FormikValuesDebug
              values={values}
              errors={errors}
              touched={touched}
            /> */}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PropertyForm;
