import React, { useState, useEffect } from "react";
import styles from "./ResidentManagement.module.css";

function ResidentManagement() {
  const [residents, setResidents] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [newResident, setNewResident] = useState("");
  const [editResident, setEditResident] = useState(null);

  useEffect(() => {
    // Fetch residents and pending requests from backend or mock API
    setResidents([
      { id: 1, name: "John Doe", apartment: "A101" },
      { id: 2, name: "Jane Smith", apartment: "B202" },
    ]);
    setPendingRequests([{ id: 3, name: "Alice Johnson", apartment: "C303" }]);
  }, []);

  const handleAddResident = () => {
    if (newResident) {
      setResidents([...residents, { id: Date.now(), name: newResident }]);
      setNewResident("");
    }
  };

  const handleEditResident = (id) => {
    const resident = residents.find((r) => r.id === id);
    setEditResident(resident);
  };

  const handleSaveEdit = () => {
    setResidents(
      residents.map((r) =>
        r.id === editResident.id ? { ...r, name: editResident.name } : r
      )
    );
    setEditResident(null);
  };

  const handleDeleteResident = (id) => {
    setResidents(residents.filter((r) => r.id !== id));
  };

  const handleApproveRequest = (id) => {
    const approved = pendingRequests.find((r) => r.id === id);
    setResidents([...residents, approved]);
    setPendingRequests(pendingRequests.filter((r) => r.id !== id));
  };

  const handleRejectRequest = (id) => {
    setPendingRequests(pendingRequests.filter((r) => r.id !== id));
  };

  return (
    <section className={styles.card}>
      <h2>Resident Management</h2>

      {/* Add Resident */}
      <div className={styles.addResident}>
        <input
          type="text"
          placeholder="Enter resident name"
          value={newResident}
          onChange={(e) => setNewResident(e.target.value)}
        />
        <button onClick={handleAddResident}>Add Resident</button>
      </div>

      {/* Resident List */}
      <h3>Residents</h3>
      <ul className={styles.residentList}>
        {residents.map((resident) => (
          <li key={resident.id}>
            {editResident?.id === resident.id ? (
              <>
                <input
                  type="text"
                  value={editResident.name}
                  onChange={(e) =>
                    setEditResident({ ...editResident, name: e.target.value })
                  }
                />
                <div className={styles.residentManagementButtons}>
                  <button onClick={handleSaveEdit}>Save</button>
                  <button onClick={() => setEditResident(null)}>Cancel</button>
                </div>
              </>
            ) : (
              <>
                <span>
                  {resident.name} - {resident.apartment}
                </span>
                <button onClick={() => handleEditResident(resident.id)}>
                  Edit
                </button>
                <button onClick={() => handleDeleteResident(resident.id)}>
                  Delete
                </button>
              </>
            )}
          </li>
        ))}
      </ul>

      {/* Pending Requests */}
      <h3>Pending Resident Requests</h3>
      <ul className={styles.requestList}>
        {pendingRequests.map((request) => (
          <li key={request.id}>
            <span>
              {request.name} - {request.apartment}
            </span>
            <button onClick={() => handleApproveRequest(request.id)}>
              Approve
            </button>
            <button onClick={() => handleRejectRequest(request.id)}>
              Reject
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ResidentManagement;
