import React from "react";
import styles from "./statistics.module.css";

const Statistics = () => {
  const stats = [
    { title: "Total Revenue", value: "$1,000,000", trend: "up" },
    { title: "Active Users", value: "5,000", trend: "down" },
    { title: "New Signups", value: "200", trend: "up" },
    { title: "Support Tickets", value: "15", trend: "neutral" },
  ];

  return (
    <div className={styles.statisticsPage}>
      <h1 className={styles.pageTitle}>Company Statistics</h1>
      <div className={styles.statsContainer}>
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`${styles.statCard} ${styles[`trend-${stat.trend}`]}`}
          >
            <h2 className={styles.statTitle}>{stat.title}</h2>
            <p className={styles.statValue}>{stat.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Statistics;
