import React, { useState } from "react";
import styles from "./bedsBathroom.module.css";
import RadioSelect from "../radioSelect/radioSelect";

const BedsBathroom = () => {
  const [selected, setSelected] = useState();

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <div className={styles.bedsBathroomContainer}>
      <h1>Rooms and beds</h1>
      <RadioSelect
        title={"BedRooms"}
        options={["Any", "1", "2", "3", "4", "5", "6", "7", "8+"]}
      />
      <RadioSelect
        title={"Beds"}
        options={["Any", "1", "2", "3", "4", "5", "6", "7", "8+"]}
      />
      <RadioSelect
        title={"Bathrooms"}
        options={["Any", "1", "2", "3", "4", "5", "6", "7", "8+"]}
      />
    </div>
  );
};

export default BedsBathroom;
