import React from "react";
import styles from "./ResidentDashboard.module.css";
import { BoxSelect } from "../../../../components/dialogues/dialogues";
import Exclaimation from "../../../../assets/svgs/Exclaimation.svg";
import { useNavigate } from "react-router-dom";

function ResidentDashboard() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/signUp");
  };

  return (
    <div className={styles.adminDashboardMain}>
      <div className={styles.adminDashboardContent}>
        <h1 className={styles.textPrimary}>Welcome, Muhammad Talha</h1>
        <h1 className={styles.textSecondary}>
          Guests can reserve your place 24 hours after you publish—here’s how
          to prepare.
        </h1>
        <br />
        <br />
        <div className={styles.flexbox}>
          <BoxSelect onClick={handleClick}>
            <div>
              <div className={styles.textDangerContainer}>
                <span className={styles.textNormal}>Complete your profile</span>
              </div>
              <br />
              <div className={styles.textDangerContainer}>
                <span className={styles.textDanger}>Required</span>
                <img src={Exclaimation} alt="Exclamation Mark" />
              </div>
              <br />
              <span className={styles.textLinkNormal}>Continue</span>
            </div>
          </BoxSelect>
        </div>
      </div>
    </div>
  );
}

export default ResidentDashboard;
