import { SpecialDotIcon } from "../svgs/rentpal";
import styles from "./specialDot.module.css";
import React from "react";

export default function SpecialDot() {
  return (
    <>
      <SpecialDotIcon
        className={styles.specialDot}
        style={{
          verticalAlign: "middle",
          marginRight: "1ch",
          marginLeft: "1ch",
        }}
      />
    </>
  );
}
