import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CalendarComponent from "../../../components/calendarComponent/calendarComponent";

import styles from "./ViewProperty.module.css";

const ViewProperty = () => {
  const { propertyId } = useParams(); // Get the property ID from the URL
  const location = useLocation();
  const { propertyData } = location.state || {}; // Access the passed property data
  const staticData = {
    reviews: 4.75,
    totalReviews: 500,
    aboutProperty: "One of the most love home on RentPal",
    fvrts: "GUEST FAVORITE",
  };

  const navigate = useNavigate();

  if (!propertyData) {
    return <p>No property details available.</p>;
  }

  return (
    <div className={styles.viewPropertyMainContainer}>
      <div className={styles.viewPropertyContentContainer}>
        <div className={styles.propertyHeaderContent}>
          <h1>Property Details</h1>
          <span>
            <a>Share</a>
            <a>Save</a>
          </span>
        </div>
        <p>{/* <strong>Property ID:</strong> {propertyId} */}</p>
        {propertyData.pictures && propertyData.pictures.length > 0 ? (
          <div className={styles.viewPropertyImagesContainer}>
            <div className={styles.leftDiv}>
              {propertyData.pictures[0] && (
                <img
                  src={propertyData.pictures[0]}
                  alt="Main Property"
                  className={styles.mainImage}
                />
              )}
            </div>
            <div className={styles.rightDiv}>
              {propertyData.pictures.slice(1, 5).map((pic, index) => (
                <div key={index} className={styles.subImageContainer}>
                  <img
                    src={pic}
                    alt={`Property Picture ${index + 2}`}
                    className={styles.subImage}
                  />
                </div>
              ))}
            </div>

            {/* Show all photos button */}
            <div
              className={styles.viewPropertyImagesBtn}
              onClick={() =>
                navigate("/showAllPhotos", {
                  state: { pictures: propertyData.pictures },
                })
              }
            >
              Show all photos
            </div>
          </div>
        ) : (
          <p>No pictures available.</p>
        )}
        <h2>
          {propertyData?.formData?.specs?.buildingName || "Building Name"}
        </h2>
        <p>
          <strong>Location:</strong>{" "}
          {propertyData?.formData?.specs?.location || "Not Specified"}
        </p>
        <p>
          <strong>Property Type:</strong> {propertyData.propertyType}
        </p>
        <p>
          <strong>Classification:</strong> {propertyData.propertyClassification}
        </p>
        <p>
          <strong>Unit:</strong> {propertyData.propertyUnit}
        </p>
        <p>
          <strong>Availability:</strong>{" "}
          {propertyData.isAvailable ? "Available" : "Not Available"}
        </p>
        <div className={styles.ratingsContainer}>
          <h3>{staticData.fvrts}</h3>
          <h3>{staticData.aboutProperty}</h3>
          <h3>{staticData.reviews}</h3>
          <span
            style={{
              background: "#DDDDDD",
              height: "50px",
              width: "1px",
              margin: "0 10px",
            }}
          ></span>
          <h3>
            {staticData.totalReviews}
            <br></br>
            <a>Reviews</a>
          </h3>
        </div>
        <CalendarComponent />
        {propertyData.videos && propertyData.videos.length > 0 && (
          <div>
            <h3>Videos:</h3>
            {propertyData.videos.map((video, index) => (
              <video
                key={index}
                controls
                style={{ width: "300px", height: "200px", margin: "10px" }}
              >
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ))}
          </div>
        )}
        <h1>Continue exploring top city homes</h1>
        <button>Show more</button>
      </div>
    </div>
  );
};

export default ViewProperty;
