import React, { useState } from "react";
import styles from "./radioSelect.module.css";

const RadioSelect = (props) => {
  const [selected, setSelected] = useState("");
  const bedroomOptions = props.options;

  const handleClick = (value) => {
    setSelected(value);
  };

  return (
    <div className={styles.bedsBathroomContainer}>
      <p>{props.title}</p>
      <div className={styles.bedsBathroomOptionsContainer}>
        {bedroomOptions.map((option) => (
          <span
            key={option}
            onClick={() => handleClick(option)}
            className={`${styles.bedsBathroomOptions} ${
              selected === option ? styles.selected : ""
            }`}
          >
            {option}
          </span>
        ))}
      </div>
    </div>
  );
};

export default RadioSelect;
