import React, { useState } from "react";
import ConfirmationModal from "../../../../components/confirmationModal/confirmationModal"; // Adjust path based on your file structure
import styles from "./announcemets.module.css";

const Announcements = () => {
  const [admins, setAdmins] = useState([
    { id: 1, name: "Admin 1", selected: false },
    { id: 2, name: "Admin 2", selected: false },
    { id: 3, name: "Admin 3", selected: false },
  ]);

  const [residents, setResidents] = useState([
    { id: 1, name: "Resident 1", selected: false },
    { id: 2, name: "Resident 2", selected: false },
    { id: 3, name: "Resident 3", selected: false },
  ]);

  const [announcement, setAnnouncement] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSelectAll = (type) => {
    if (type === "admins") {
      setAdmins((prev) => prev.map((admin) => ({ ...admin, selected: true })));
    } else {
      setResidents((prev) =>
        prev.map((resident) => ({ ...resident, selected: true }))
      );
    }
  };

  const handleToggleSelection = (type, id) => {
    if (type === "admins") {
      setAdmins((prev) =>
        prev.map((admin) =>
          admin.id === id ? { ...admin, selected: !admin.selected } : admin
        )
      );
    } else {
      setResidents((prev) =>
        prev.map((resident) =>
          resident.id === id
            ? { ...resident, selected: !resident.selected }
            : resident
        )
      );
    }
  };

  const handleSendAnnouncement = () => {
    // Logic to send the announcement
    const selectedAdmins = admins.filter((admin) => admin.selected);
    const selectedResidents = residents.filter((resident) => resident.selected);
    console.log("Selected Admins:", selectedAdmins);
    console.log("Selected Residents:", selectedResidents);
    console.log("Announcement:", announcement);

    // Close modal after sending
    setModalOpen(false);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Announcements</h1>

      <div className={styles.recipientsSection}>
        <h2>Admins</h2>
        <button
          className={styles.selectAllButton}
          onClick={() => handleSelectAll("admins")}
        >
          Select All
        </button>
        <ul>
          {admins.map((admin) => (
            <li key={admin.id}>
              <label>
                <input
                  type="checkbox"
                  checked={admin.selected}
                  onChange={() => handleToggleSelection("admins", admin.id)}
                />
                {admin.name}
              </label>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.recipientsSection}>
        <h2>Residents</h2>
        <button
          className={styles.selectAllButton}
          onClick={() => handleSelectAll("residents")}
        >
          Select All
        </button>
        <ul>
          {residents.map((resident) => (
            <li key={resident.id}>
              <label>
                <input
                  type="checkbox"
                  checked={resident.selected}
                  onChange={() =>
                    handleToggleSelection("residents", resident.id)
                  }
                />
                {resident.name}
              </label>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.messageSection}>
        <h2>Announcement</h2>
        <textarea
          value={announcement}
          onChange={(e) => setAnnouncement(e.target.value)}
          placeholder="Type your announcement here..."
        />
      </div>

      <button
        className={styles.sendButton}
        onClick={() => setModalOpen(true)}
        disabled={!announcement.trim()}
      >
        Send
      </button>

      {isModalOpen && (
        <ConfirmationModal
          title="Send Announcement"
          message="Are you sure you want to send this announcement?"
          onConfirm={handleSendAnnouncement}
          onCancel={() => setModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Announcements;
